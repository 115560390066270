import { useSessionStore } from "../../../../Stores/SessionStore";
import StructureDetails from "./StructureDetails";

export default function GroupDetails(props) {
	const store = useSessionStore();

	async function getGroup(id) {
		return store.StructureService.GetGroup(id);
	}

	async function createGroup(group) {
		
		var groupcheck = await  store.StructureService.CreateGroup(group);
		 var wali = await store.LoadAuthState();
		
		return groupcheck
		
			
		
	}

	async function updateGroup(group) {
		return store.StructureService.UpdateGroup(group);
	}

	return 	<StructureDetails 	getStructure={ getGroup }
								updateStructure={ updateGroup } 
								createStructure={ createGroup } 
								view={ props.view } 
								title="Groups" 
								name="Group" 
								childName="Market Positions"
								add={props.add}>
			</StructureDetails>
}