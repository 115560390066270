import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { PaymentInstructionStatuses } from "../../../../../Utilities/Enums";
import DateField from "../../../../Core/Forms/DateField";
import InputField from "../../../../Core/Forms/InputField";
import NumberField from "../../../../Core/Forms/NumberField";
import SelectField from "../../../../Core/Forms/SelectField";
import Panel from "../../../../Core/Panel";
import { hasControl,Controls } from "../../../../../Utilities/Controls";
import { useSessionStore } from "../../../../../Stores/SessionStore";

export default function FinancingDetails(props) {
	const formikContext = useFormikContext();
	const store = useSessionStore();
	var paymentDateValue = formikContext.values.PaymentDate;
	var maturityDateValue = formikContext.values.MaturityDate;
	const fullyRecoveredValue = formikContext.values.FacilityTypeId === 2? formikContext.values.RecoveredDate : formikContext.values.FullyRecoveredDate;

	const batchDates = formikContext.values.BatchDates;
	const canOverride = hasControl(Controls.Areas.CreditManagement,Controls.SubAreas.PaymentManagement,Controls.Sections.PaymentInstructionDetailsOverride,store.Session);

	useEffect(() => {
		
		//checks if Maturity and Payment Date have values
		if(maturityDateValue && paymentDateValue) {
			let maturityDate = DateTime.fromISO(maturityDateValue);
			let paymentDate = DateTime.fromISO(paymentDateValue);
			
			//check for wether transaction is Debt transaction or payable transaction
			if(formikContext.values.FacilityTypeId === 2)
				{
					//checks if the transaction is recovered
					if(formikContext.values.IsRecovered)
				{
					let recoveryDate = DateTime.fromISO(fullyRecoveredValue)
					//if recovered sets funding days to RecoveryDate - PaymentDate +1 	
					formikContext.setFieldValue("FundingDays", Math.floor(recoveryDate.diff(paymentDate, "days").days+1, true));
				}
				//checks Differnce between Date today and Maturity Date
				else if(maturityDate.diffNow("days").days < 0 && !formikContext.values.IsRecovered) {
					//the funding days must keep ticking if the PI is not yet recovererd
			
					//checks for negative Days
					if(maturityDate.diff(paymentDate,"days").days <= 0)
					{
						formikContext.setFieldValue("FundingDays",maturityDate.diff(paymentDate, "days").days+1, true);
					}
					// as standard sets Funding days to today - PaymentDate +1 As users Cannot Back date in Most Cases besides having override
					else{
					formikContext.setFieldValue("FundingDays", Math.floor(DateTime.now().diff(paymentDate, "days").days+1), true);
					}
				} 
				//sets Funding Days to Maturity Date - PaymentDate +1
					else{
					formikContext.setFieldValue("FundingDays", maturityDate.diff(paymentDate, "days").days+1, true);
						
				}
			}
			else{
				//checks if the transaction is recovered
			 if(formikContext.values.IsFullyRecovered)
				{
					let recoveryDate = DateTime.fromISO(fullyRecoveredValue)
					formikContext.setFieldValue("FundingDays", Math.floor(recoveryDate.diff(paymentDate, "days").days+1, true));
				}
					//checks Differnce between Date today and Maturity Date
			else if(maturityDate.diffNow("days").days < 0 && !formikContext.values.IsRecovered) {
				//the funding days must keep ticking if the PI is not yet recovererd
				if(maturityDate.diff(paymentDate,"days").days <= 0)
				{
					formikContext.setFieldValue("FundingDays",maturityDate.diff(paymentDate, "days").days+1, true);
				}
				// as standard sets Funding days to today - PaymentDate +1 As users Cannot Back date in Most Cases besides having override
				else{
				formikContext.setFieldValue("FundingDays", Math.floor(DateTime.now().diff(paymentDate, "days").days+1), true);
				}
			} 
				else{
					//sets Funding Days to Maturity Date - PaymentDate +1
				formikContext.setFieldValue("FundingDays", maturityDate.diff(paymentDate, "days").days+1, true);
					
			}

		}
	}

	}, [paymentDateValue, maturityDateValue]);

	function handleBatchDateChange(id) {
		let batchDate = batchDates.find(b => b.Id === id);

		if(batchDate) {
			formikContext.setValues({ PaymentDate: batchDate.BatchDate, FacilityBatchDateId: batchDate.Id });
		}
	}

	function handlePaymentDateChange() {
		if((canOverride))
		{
			
			formikContext.setFieldValue("MaturityDate",null,true);
			formikContext.setFieldValue("FundingDays",0,true);
			formikContext.values.MaturityDate =  null;
			maturityDateValue = null;

		

			props.dateChanged()
			
		}
		else{
			props.dateChanged()
		}
	}
	
	
	const piStatus = parseInt(formikContext.values["PaymentInstructionStatusId"]);
	

	return 	<Panel title="Payment Instruction Details">
				<Row>
					<Col>
						<InputField disabled={ props.processing  && !canOverride} view={props.view} className="mb-50" required label="Number" name="Number"></InputField>
					</Col>
					<Col>
						<InputField disabled={ props.processing && !canOverride} view={props.view} className="mb-50" required label="Name" name="Name"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						{
							!props.processing && !props.recoveries &&
							<SelectField view={props.view} className="mb-50" required label="Status" name="PaymentInstructionStatusId">
								<option value="">-- Select --</option>
								<option value="1">Open</option>
								<option value="2">Pending</option>
								{
									piStatus === PaymentInstructionStatuses.AwaitingConfirmation &&
									<option value="3">Awaiting Confirmation</option>
								}
								{
									piStatus === PaymentInstructionStatuses.Confirmed &&
									<option value="4">Confirmed</option>
								}
								<option value="5">Declined</option>
								{
									piStatus === PaymentInstructionStatuses.AwaitingAuthorisation &&
									<option value="6">Awaiting Authorisation</option>
								}
								{
									piStatus === PaymentInstructionStatuses.Authorised &&
									<option value="7">Authorised</option>
								}	
								<option value="8">NTU</option>
								{
									piStatus === PaymentInstructionStatuses.Processed &&
									<option value="9">Processed</option>
								}	
							</SelectField>
						}
							
						{
							(props.processing  && canOverride) &&
							<SelectField view={props.view} className="mb-50" required label="Status" name="PaymentInstructionStatusId">
								<option value="2">Pending</option>
								<option value="7">Authorised</option>
								<option value="8">NTU</option>
								<option value="9">Processed</option>
							</SelectField>
						}
						{
							((props.processing && !canOverride) || props.recoveries) &&
							<SelectField view={props.view} className="mb-50" required label="Status" name="PaymentInstructionStatusId">
								
								<option value="7">Authorised</option>
								<option value="8">NTU</option>
								<option value="9">Processed</option>
							</SelectField>
						}
					</Col>
					<Col>
						<InputField view={props.view}  className="mb-50" label="Comment" name="Comment"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						{
							(!batchDates || batchDates.length === 0) &&
							<DateField onChange={ handlePaymentDateChange} disabled={ props.processing  && !canOverride } view={props.view} minDate={ canOverride? null:paymentDateValue } className="mb-50" required label="Run Date" name="PaymentDate"></DateField>
						}
						{
							batchDates && batchDates.length > 0 &&
							<SelectField disabled={ props.processing && !canOverride } view={props.view} className="mb-50" required label="Run Date" name="PaymentDate" onChange={ e => handleBatchDateChange(e) }>
								<option value="">Select a date of payment</option>
								{
									batchDates.map((date, index) =>
									<option key={index} value={date.Id}>{DateTime.fromISO(date.BatchDate).toFormat("yyyy/MM/dd")}</option>)
								}
							</SelectField>
						}
					</Col>
					<Col>
				
						<DateField onChange={ props.dateChanged } disabled={ props.processing && !canOverride } minDate={ canOverride? null:paymentDateValue } view={props.view} className="mb-50" required={ !formikContext.values.IsReimbursementInstruction ? true : false} invalid={ !formikContext.values.IsReimbursementInstruction || (formikContext.values.maturityDate === null || formikContext.values.maturityDate === undefined)} label="Maturity Date" name="MaturityDate"></DateField>
					</Col>
				</Row>				
				<Row>
					<Col>
						<NumberField prefix={ formikContext.values["Currency"] } disabled={ props.processing && !canOverride} view={props.view} className="mb-50" label="Limit" name="Limit"></NumberField>
					</Col>
					<Col>
						<InputField disabled view={props.view} className="mb-50" label="Funding Days" name="FundingDays"></InputField>
					</Col>
				</Row>
			</Panel>
}