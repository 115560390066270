import { DateTime } from "luxon";
import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import NumberValue from "../../../Core/NumberValue";
import ApprovalValue from "../ApprovalValue";

export default function Reimbursement(props) {
	return	<Row className="border p-1 mb-1">
				<Col>
					<Row className="ps-50">
						<ApprovalValue label="Group">
							{ props.paymentInstruction.GroupName }
						</ApprovalValue>
						<ApprovalValue label="Accounting Code">
							{ props.paymentInstruction.GroupAccountingCode }
						</ApprovalValue>
					</Row>
					<Row className="ps-50">
						<ApprovalValue label="Bank">
							{ props.paymentInstruction.GroupBankAccount.BranchName }
						</ApprovalValue>
						<ApprovalValue label="Branch Code">
							{ props.paymentInstruction.GroupBankAccount.BranchCode }
						</ApprovalValue>
						<ApprovalValue label="Account Number">
							{ props.paymentInstruction.GroupBankAccount.BankAccountNumber }
						</ApprovalValue>
					</Row>
					<Row className="mt-1">
						<Col>
							<Table size="sm">
								<tbody>
									<tr className="border-top border-bottom-2">
										<td width="180" style={{ maxWidth: "180px" }} className="fw-bold">Reimbursement No.</td>
										<td width="180" style={{ maxWidth: "180px" }} className="fw-bold">Date</td>
										<td width="80" style={{ maxWidth: "80px" }} className="text-center fw-bold">Currency</td>
										<td className="text-end fw-bold">Reimbursement Amount</td>
									</tr>
									{
										props.paymentInstruction.Reimbursements.map(r =>
										<tr>
											<td>{ r.DocumentNumber }</td>
											<td>{ DateTime.fromISO(r.DateTimeStamp).toFormat("dd MMMM yyyy") }</td>
											<td className="text-center">{ props.paymentInstruction.CurrencyAbbreviation }</td>
											<td className="text-end"><NumberValue currency={ r.Currency } value={ r.PaymentAmount  }></NumberValue></td>
										</tr>)
									}	
									<tr>
										<td colSpan={2} className="fw-bolder">Totals</td>
										<td className="text-center fw-bolder">{ props.paymentInstruction.CurrencyAbbreviation }</td>
										<td className="text-end fw-bolder"><NumberValue currency={ props.paymentInstruction.CurrencySymbol } value={ props.paymentInstruction.ReimbursementsTotal }></NumberValue></td>
									</tr>								
								</tbody>
							</Table>
						</Col>
					</Row>
				</Col>
			</Row> 	
}