import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { DateFormats, DateInputFormats } from "../../../Utilities/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { DateTime } from "luxon";
import DateValue from "../DateValue";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

export default function DateField(props) {
	const { setFieldValue, errors } = useFormikContext();
	const [field] = useField(props.name);
	
	const BootstrapInput = ({defaultValue, value, ...props}, ref) => (
		<InputGroup className={ (errors[field.name] ? "is-invalid" : "") } >
			<InputGroup.Text>
				<FontAwesomeIcon icon={ faCalendar }></FontAwesomeIcon>
			</InputGroup.Text>
			<Form.Control onKeyPress={ handleKeyPress }  disabled={props.disabled} required={ props.required } className={ (errors[field.name] ? "is-invalid" : "") } placeholder={ props.placeholder || "Select a date"} onClick={props.onClick} ref={ref} defaultValue={defaultValue}></Form.Control>
		</InputGroup>
	);

	function handleInputChanged(val) {
		var date = DateTime.fromJSDate(val).toISODate();
		
		setFieldValue(field.name, date, true);

		if(props.onChange) {
			props.onChange(date);
		}
	}

	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	var selectedDate = (field.value ? DateTime.fromISO(field.value).toJSDate() : "")
	
	return 	<Form.Group as={Row } className={`align-items-center ${props.className || ""}`}>
				<Form.Label column>{props.label}</Form.Label>
				{
					props.view &&
					<Col xs={ props.col || 8  }>
						<DateValue style={{ cursor: "not-allowed", color: "#004878" }} date={ field.value } outputFormat={ DateFormats.FullLongDate }></DateValue>
					</Col>
				}
				{
					!props.view &&
					<Col xs={ props.col || 8 } className={props.fmr ? "pe-0" : ""}>
						<Flatpickr placeholder={ props.placeholder } options={{ allowInput: true, disableMobile : true, maxDate: props.maxDate, minDate: props.minDate, dateFormat: props.dateFormat || DateInputFormats.FullLongDate }} readOnly={false} required={ props.required } disabled={ props.disabled } render={ BootstrapInput } value={selectedDate} onChange={([date]) => handleInputChanged(date) }/>
					</Col>
				}
			</Form.Group>
}