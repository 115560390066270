import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import PageHeader from "../../../Core/PageHeader";
import Documents from "../../../Core/Documents/Documents";
import RecoveryStateTable from "./RecoveryStateTable";
import { faWallet } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BusyIndicator from "../../../Core/BusyIndicator";
import BackButton from "../../../Core/BackButton";
import swal from "sweetalert";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const PaymentInstructionRecoveries = observer(function PaymentInstructionRecoveries(props) {

	const [piName, setPiName] = useState('');
	UseDocumentTitle('Payment Recoveries for \''+ piName +'\'');

	const store = useSessionStore();
	const params = useParams();

	const [uploadingDocument, setUploadingDocument] = useState(false);
	const [state, setState] = useState(null);

	async function getRecoveryState() {
		setState(null);
		let result = await store.PaymentInstructionRecoveriesService.GetPaymentInstructionRecoveryState(params.paymentInstructionId);

		setPiName(result.Data.PaymentInstructionName);

		if(result && result.Success) {
			setState(result.Data);
		}
	}

	function handleImportDiscarded() {
		setState({...state, ActiveExport: null });
	}

	async function handleDocumentUpload(entity) {
		setUploadingDocument(true);
		let result = await store.PaymentInstructionRecoveriesService.UploadDocumentForPaymentInstruction(params.paymentInstructionId, entity.Documents[entity.Documents.length - 1]);

		if(result && result.Success) {
			setUploadingDocument(false);
			setState(entity);
			swal({ title: "Success", text: "Successfully saved document.", icon: "success", closeOnClickOutside: false });
		}
	}

	useEffect(() => {
		getRecoveryState();
	}, []);

	return 	<React.Fragment>
				<PageHeader icon={ faWallet } title={`Recover Payments for ${ state ? state.PaymentInstructionName : "..." }`} helpItemDescriptor="PaymentInstruction/PaymentRecoveries"
								crumbs={[{ Title: "Credit Management" }, { Title: "Payment Management" }, { Title: "Payment Recoveries" }]}>
					<BackButton></BackButton>
				</PageHeader>
				{
					state &&
					<React.Fragment>
						<RecoveryStateTable onImportDiscarded={ handleImportDiscarded } onRefresh={ getRecoveryState } allowRecoveries state={ state }></RecoveryStateTable>
						{
							!uploadingDocument &&
							<Documents preventDelete allowDocChanges entity={ state } updateEntity={ handleDocumentUpload }></Documents>
						}
					</React.Fragment>
				}
				<BusyIndicator show={ state === null || uploadingDocument }></BusyIndicator>
			</React.Fragment>
});

export default PaymentInstructionRecoveries;