import { Col, Form, Row } from "react-bootstrap";
import { Field, useFormikContext } from "formik";

export default function CheckboxField(props) {
	const formikContext = useFormikContext();
	const view = formikContext.values.View || props.view;

	return 	<Form.Group as={Row} className={`${props.className || ""} align-items-center`}>
				{
					!props.swap && props.label &&
					<Form.Label column>{ props.label }</Form.Label>
				}
				{
					!view &&
					<Col xs={ props.col || 8}>
						<Field className="form-check-input" type="checkbox" name={ props.name } disabled={props.disabled}></Field>
					</Col>
				}
				{
					view &&
					<Col xs={ props.col || 8 }>
						<Form.Check style={{ cursor: "not-allowed" }} readOnly={true} checked={ formikContext.values[props.name] }></Form.Check>
					</Col>
				}				
				{
					props.swap && props.label &&
					<Form.Label column>{ props.label }</Form.Label>
				}
			</Form.Group>
}