import { Document, Page, View, Text } from "@react-pdf/renderer";
import FacilityMovementHeader from "./Components/FacilityMovement/FacilityMovementHeader";
import FacilityMovementTransactions from "./Components/FacilityMovement/FacilityMovementTransactions";
import { styles } from "./StyleSheet";
import { DateTime } from "luxon";
import moment from "moment";
import FacilityMovementClosingBalnce from "./Components/FacilityMovement/FacilityMovementClosingBalance";
import React from "react";

export default function FacilityMovementReport(props){

    var dateTimeSamp = DateTime.now();
    
    return(
        <Document>
            <Page size="A4" style={[styles.page, {paddingBottom: 20}]}>
                <View style={styles.section}>
                    <FacilityMovementHeader data={props.data.Header} detailed={props.data.Detailed}></FacilityMovementHeader>
                    <View style={[styles.table, {paddingBottom:70}]}>
                        <FacilityMovementTransactions data={props.data.Body} detailed={props.data.Detailed} openingDate={props.data.Header.FromDate}></FacilityMovementTransactions>
                        <View style={[styles.pageDate, { bottom:-5, paddingRight:10, paddingLeft:10 }]}>
                            <FacilityMovementClosingBalnce footer={props.data.Footer} detailed={props.data.Detailed} currency={props.data.Body.Currency}></FacilityMovementClosingBalnce>
                        </View>
                    </View>
                    <React.Fragment>
                        <Text style={[styles.pageNumbers, {bottom: -8}]} render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} of ${totalPages}`
                        )} fixed />
                        <Text style={[styles.pageDate, {bottom: -20}]}>
                            {moment(dateTimeSamp.toISO()).format("DD/MM/YYYY HH:mma")}
                        </Text>
                    </React.Fragment>
                </View>
            </Page>
        </Document>
    )
}