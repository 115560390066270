import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { FacilityStatuses, UserRoles } from "../../../../../Utilities/Enums";
import { isFieldEnabled } from "../../../../../Utilities/Facilities";
import DateField from "../../../../Core/Forms/DateField";
import Panel from "../../../../Core/Panel";
import swal from "sweetalert"
import moment from "moment";

export default function Dates() {
	var formikContext = useFormikContext();
	const view = formikContext.values.View;
	const store = useSessionStore();

	const disabled = !isFieldEnabled([UserRoles.Admin, UserRoles.GroupAdmin,UserRoles.UserWithControl], [FacilityStatuses.Active, FacilityStatuses.Pending], formikContext.values.FacilityStatusID, store.Session) &&
					!isFieldEnabled([], [FacilityStatuses.Expired], formikContext.values.FacilityStatusID, store.Session);

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {

		if(loaded === true) {
			var facilityTermId = formikContext.values.FacilityTermID;

			if (facilityTermId > 0) {
				var termMonths = facilityTermId * 12;

				var terminationDate = formikContext.values.TerminationDate;
				var advanceDate = formikContext.values.AdvanceDate;

				if (advanceDate && terminationDate) {
					var start = DateTime.fromISO(advanceDate);
					var end = DateTime.fromISO(terminationDate);

					var diffinMonths = end.diff(start, 'months');
					
					let finalMonths = diffinMonths.months;

					if (finalMonths !== termMonths) {
						swal({ title: "Warning", text: "The period between the 'Advance Date' and the 'Termination Date' does not match the 'Facility Terms", icon: "warning", closeOnClickOutside: false });
					}
				}
			}
		} else {
			setLoaded(true);
		}

	}, [formikContext.values.TerminationDate, formikContext.values.AdvanceDate, formikContext.values.FacilityTermID] )

	let dateToday = new Date();
	dateToday.setHours(0, 0, 0, 0);
	// const to make placeholder work
	const termdate = formikContext.values.TerminationDate
	return 	<Panel title="Dates">
				<Row>
					<Col><DateField required view={ view } disabled={ disabled } name="AdvanceDate" label="Advance Date"></DateField></Col>
					<Col><DateField required view={ view } placeholder={DateTime.fromISO(formikContext.values.TerminationDate).ts < DateTime.fromISO(dateToday).ts ? moment(termdate).format("DD MMMM YYYY"): null } disabled={ disabled }  minDate={dateToday}   name="TerminationDate" label="Termination Date"></DateField></Col>
				
				</Row>
			</Panel>
}