import { Col, Row } from "react-bootstrap";
import { FacilityConditions, FacilityStatuses, UserRoles } from "../../../../../Utilities/Enums";
import { getCurrentRate, getTotalMargins, isFieldEnabled } from "../../../../../Utilities/Facilities";
import { RateHistoryTypes } from "../../../../../Utilities/Enums";
import CheckboxField from "../../../../Core/Forms/CheckboxField";
import DateField from "../../../../Core/Forms/DateField";
import NumberField from "../../../../Core/Forms/NumberField";
import SelectField from "../../../../Core/Forms/SelectField";
import Panel from "../../../../Core/Panel";
import RateHistoryField from "./RateHistories/RateHistoryField";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import RateValue from "./RateHistories/RateValue";

export default function FeesAndCosts(props) {
	const store = useSessionStore();
	const disabled = !isFieldEnabled([UserRoles.Admin, UserRoles.GroupAdmin,UserRoles.UserWithControl], [FacilityStatuses.Active, FacilityStatuses.Pending], props.values.FacilityStatusID, store.Session);
	const ratesDisabled = !isFieldEnabled([], [FacilityStatuses.Active, FacilityStatuses.Pending], props.values.FacilityStatusID, store.Session);
	const view = props.values.View;

	function getIntervalSelect(name) {
		return <SelectField view={ view } disabled={ disabled } fast name={name}>
					<option value="">SELECT</option>
					<option value="1">Per Issuance</option>
					<option value="2">On Commencement</option>
					<option value="3">Monthly</option>
					<option value="4">Per Annum</option>
					<option value="5">Quarterly</option>
				</SelectField>
	}

	function getTotalCostOfFinance() {
		return getTotalMargins(props.values.CostofFinanceFundersMargins, props.values.CostofFinanceTradingMargins,
								props.values.UseFixedBaseRate, props.values.BaseInterestRates, props.financialTerms, parseInt(props.values.FinancialTermId));
	}

	function getTotalUnderutilisation() {
		return getTotalMargins(props.values.UnderutilisationFundersMargins, props.values.UnderutilisationTradingMargins,
			props.values.UseFixedBaseRate, props.values.BaseInterestRates, props.financialTerms, parseInt(props.values.FinancialTermId));
	}

	return <Panel  title="Fees and Costs">
				<Row className="mb-50 align-items-center">
					<Col xs={2}><CheckboxField col={2} label="Use Fixed Base Rate of Interest" name="UseFixedBaseRate"></CheckboxField></Col>
					<Col xs={4}><RateHistoryField view={ view } disabled={ ratesDisabled } rateType={ RateHistoryTypes.BaseInterestRate } col={6} title="Base Rate of Interest" label="Base Rate of Interest" fieldName="BaseInterestRates" rates={ props.values.BaseInterestRates }></RateHistoryField></Col>
					
					<Col xs={6}><DateField view={ view }  required={ props.values.UseFixedBaseRate } label="Base Rate End Date" name="FixedBaseRateExpiryDate"></DateField></Col>
				</Row>
				<Row className="mb-50 align-items-center">
					<Col xs={6}>
						<RateValue view={ view } value={ getTotalCostOfFinance() } label="Cost of Finance - Base Rate Of Interest / Funders Margin / Trading Margin"></RateValue>
					</Col>
					<Col xs={2}>
						<RateValue view={ view } value={(props.values.UseFixedBaseRate ? getCurrentRate(props.values.BaseInterestRates) : 0)}></RateValue>
					</Col>
					<Col xs={2}><RateHistoryField view={ view } disabled={ ratesDisabled } rateType={ RateHistoryTypes.CostOfFinanceFundersMargin } title="Cost of Finance - Funders Margin" fieldName="CostofFinanceFundersMargins" rates={ props.values.CostofFinanceFundersMargins }/></Col>
					<Col xs={2}><RateHistoryField view={ view } disabled={ ratesDisabled } rateType={ RateHistoryTypes.CostOfFinanceTradingMargin } title="Cost of Finance - Trading Margin" fieldName="CostofFinanceTradingMargins" rates={ props.values.CostofFinanceTradingMargins }/></Col>
				</Row>
				<Row className="mb-50 align-items-center">
					<Col xs={6}>
						<RateValue view={ view } value={ getTotalUnderutilisation() } label="Underutilisation - Base Rate Of Interest / Funders Margin / Trading Margin"></RateValue>
					</Col>
					<Col xs={2}>
						<RateValue view={ view } value={(props.values.UseFixedBaseRate ? getCurrentRate(props.values.BaseInterestRates) : 0)}></RateValue>
					</Col>
					<Col xs={2}>
						<RateHistoryField view={ view } rateType={ RateHistoryTypes.UnderutilisationFeeFunderMargin } title="Underutilisation - Funders Margin" fieldName="UnderutilisationFundersMargins"
											disabled={ ratesDisabled || parseInt(props.values.FacilityConditionsID) === FacilityConditions.InterestAccruingOnDrawnPortionsOnly } rates={ props.values.UnderutilisationFundersMargins }/>
					</Col>
					<Col xs={2}>
						<RateHistoryField view={ view } rateType={ RateHistoryTypes.UnderutilisationFeeTradingMargin } title="Underutilisation - Trading Margin" fieldName="UnderutilisationTradingMargins"
											disabled={ ratesDisabled || parseInt(props.values.FacilityConditionsID) === FacilityConditions.InterestAccruingOnDrawnPortionsOnly } rates={ props.values.UnderutilisationTradingMargins }/>
					</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="FacilityFee" label="Facility Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.FacilityFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="StructureFee" label="Structuring Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.StructuringFeeFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="RenewalFee" label="Renewal Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.RenewalFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ props.currency } name="InsuranceCost" label="Insurance Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.InsuranceCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="ServiceFee" label="Service Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.ServiceFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ props.currency } name="LegalCost" label="Legal Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.LegalCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="PlatformFee" label="Platform Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.PlatformFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ props.currency } name="IssuingCost" label="Issuing Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.IssuingCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="TradingFee" label="Trading Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.TradingFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ props.currency } name="CollateralManagementCost" label="Collateral Management Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.CollateralManagementCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="OtherFees" label="Other Fees"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.OtherFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ props.currency } name="DocumentFee" label="Document Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.DocumentFeeFrequency") }</Col>
				</Row>
				<Row className="d-flex align-items-center mb-50">
					<Col xs={2}><label className="control-label">Trading Discount Split</label></Col>
					<Col xs={4}>
						<Row>
							<Col xs={5}><NumberField view={ view } disabled={ disabled } required fast name="TradingDiscountSplitNumerator" suffix="%"></NumberField></Col>
							<Col xs={2} className="text-center"><label>:</label></Col>
							<Col xs={5}><NumberField view={ view } disabled={ disabled } required fast name="TradingDiscountSplitDenominator" suffix="%"></NumberField></Col>
						</Row>
					</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ props.currency } name="OtherCosts" label="Other Costs"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FacilityFeesFrequencies.OtherCostsFrequency") }</Col>
				</Row>
				<Row>
					<Col><NumberField view={ view } disabled={ disabled } fast name="Factor" label="Factor"></NumberField></Col>
					<Col><CheckboxField disabled={ disabled } label="Linked Trading" name="LinkedTrading"></CheckboxField> </Col>
				</Row>
			</Panel>
}