import {View, Text} from '@react-pdf/renderer';
import { styles } from '../../../StyleSheet';
import { FacilityTypes } from '../../../../../Utilities/Enums';
import { formatCurrencyValue } from "../../../../../Utilities/Currencies";

export default function TransactionsDetails(props){
        if( props.facilityType !== FacilityTypes.TradeFinance){
            return(
               
                <View style={[styles.tableRowWrapping, {borderBottom:0.5, borderColor: 'lightgrey', paddingBottom:10}]}>
                
                    <Text style={[styles.detailsOfCustomerTransactions, {maxLines:1, overflow:'hidden', textOverflow:'ellipsis'}]}>Customer: <Text style={styles.boldFont}>{props.data.Contact.CompanyName}</Text></Text>
                    <Text style={styles.detailsOfCustomerTransactions}>Accounting Code: <Text style={styles.boldFont}>{props.data.Contact.AccountingCode}</Text></Text>
                    <Text style={styles.detailsOfCustomerTransactions}>Customer Limit: <Text style={styles.boldFont}>{formatCurrencyValue(props.currency,props.data.Limit)}</Text></Text>
                    <Text style={styles.detailsOfCustomerTransactions}>Customer Balance: <Text style={styles.boldFont}>{formatCurrencyValue(props.currency,props.data.Balances.ActualTotal)}</Text></Text>
                
                </View>
            
            )
        }else{
            return(
               
               
               <View style={{borderBottom:0.5, borderColor: 'lightgrey', paddingBottom:10}}>
                
                    <View style={styles.tableRowWrapping}>
                        <Text style={[styles.detailsOfCustomerTransactions, {maxLines:1, overflow:'hidden', textOverflow:'ellipsis'}]}>Vendor: <Text style={styles.boldFont}>{props.data.Contact.CompanyName}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Accounting Code: <Text style={styles.boldFont}>{props.data.Contact.AccountingCode}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Vendor Limit: <Text style={styles.boldFont}>{formatCurrencyValue(props.currency,props.data.Limit)}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Vendor Balance: <Text style={styles.boldFont}>{formatCurrencyValue(props.currency,props.data.Balances.ActualTotal)}</Text></Text>                       
                    </View>
                    <View style={styles.tableRowWrapping}>
                        <Text style={styles.detailsOfVendorTransactions}>Bank: <Text style={styles.boldFont}>{props.data.Contact.BankAccounts[0].BankInstitution}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Branch Code: <Text style={styles.boldFont}>{props.data.Contact.BankAccounts[0].BranchCode}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Account Number: <Text style={styles.boldFont}>{props.data.Contact.BankAccounts[0].BankAccountNumber}</Text></Text>
                    </View>
                </View>
            
            )
        }
}