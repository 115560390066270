import { Col, Row } from "react-bootstrap";
import Panel from "../../../../Core/Panel";
import InputField from "../../../../Core/Forms/InputField";
import SelectField from "../../../../Core/Forms/SelectField";
import CheckboxField from "../../../../Core/Forms/CheckboxField";
import FacilityFunderLimitField from "./Funders/FacilityFunderLimitField";
import { useFormikContext } from "formik";
import FacilityFundersModal from "./Funders/FacilityFundersModal";
import FacilityFunderLimitHistoryModal from "./Funders/FacilityFunderLimitHistoryModal";
import { useState,useEffect } from "react";
import { getCurrentFunderLimit, isFieldEnabled } from "../../../../../Utilities/Facilities";
import NumberField from "../../../../Core/Forms/NumberField";
import { FacilityStatuses } from "../../../../../Utilities/Enums";
import { UserRoles } from "../../../../../Utilities/Enums";
import { useSessionStore } from "../../../../../Stores/SessionStore";


export default function FacilityDetails(props) {
	const formikContext = useFormikContext();
	const store = useSessionStore();
	const view = formikContext.values.View;
	const [financialTerms, setFinancialTerms] = useState(null);
	const [showFundersModal, setShowFundersModal] = useState(false);
	const [showFunderLimitsModal, setShowFunderLimitsModal] = useState(false);
	const [activeFunderIndex, setActiveFunderIndex] = useState(null);

	const allWhileActive = !isFieldEnabled([], [FacilityStatuses.Active, FacilityStatuses.Pending], formikContext.values.FacilityStatusID, store.Session);
	const adminWhileActive = !isFieldEnabled([UserRoles.Admin], [FacilityStatuses.Active, FacilityStatuses.Pending], formikContext.values.FacilityStatusID, store.Session);
	const nonUserWhileActive = !isFieldEnabled([UserRoles.Admin, UserRoles.GroupAdmin,UserRoles.UserWithControl], [FacilityStatuses.Active, FacilityStatuses.Pending],
												formikContext.values.FacilityStatusID, store.Session);
	const nonUserWhileExpired = !isFieldEnabled([UserRoles.Admin, UserRoles.GroupAdmin,UserRoles.UserWithControl], [FacilityStatuses.Active, FacilityStatuses.Pending,FacilityStatuses.Expired],
												formikContext.values.FacilityStatusID, store.Session);

	function handleFacilityFunderLimitsClicked(index) {
		setActiveFunderIndex(index);
		setShowFundersModal(false);
		setShowFunderLimitsModal(true);
	}

	function handleFundersClicked() {
		if(!allWhileActive) {
			setShowFundersModal(true)
		}
	}



	function handleFundersModalClosed() {
		setShowFundersModal(false);
	}

	function getFundersLimit() {
		//set the facilities limit
		var limit = 0;

		for(var i = 0; i < formikContext.values.LinkedFunders.length; i++) {
			if(formikContext.values.LinkedFunders[i].Active) {
				limit += getCurrentFunderLimit(formikContext.values.LinkedFunders[i]);
			}
		}

		return limit;
	}


	return 	<Panel title="Facility Details">
				<Row>
					<Col>
						<InputField view={ view } disabled={ adminWhileActive } required fast name="FacilityNumber" label="Number" className="mb-50"></InputField>
					</Col>
					<Col>
						<InputField view={ view } disabled={ adminWhileActive } required fast name="AccountNumber" label="Account Number" className="mb-50"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={ view } disabled={ adminWhileActive } required fast name="Name" label="Name" className="mb-50"></InputField>
					</Col>
					<Col>
						<SelectField view={ view } disabled={ nonUserWhileActive }  fast name="OffTakerContactId" label="The Offtaker" className="mb-50">
							<option value="">SELECT</option>
							{
								props.offtakers && props.offtakers.map(c =>
									<option key={ c.Id } value={c.Id}>{c.Name}</option>)
							}
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<FacilityFunderLimitField view={ view } className="mb-50" label="Limit" onClick={ handleFundersClicked } currency={ props.currency } limit={ getFundersLimit() }></FacilityFunderLimitField>
					</Col>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive }  name="FacilityTypeID" label="Type" className="mb-50">
							<option value="">SELECT</option>
							<option value="1">Trade Finance</option>
							<option value="2">Invoice Discounting</option>
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={ view } disabled={ adminWhileActive }  required fast name="FacilityConditionsID" label="Conditions" className="mb-50">
							<option value="">SELECT</option>
							<option value="1">Interest Accruing on Drawn Portions Only</option>
							<option value="2">Interest Accruing on Facility Limit</option>
						</SelectField>
					</Col>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive } name="FinancialTermId" label="Cost of Finance Terms" className="mb-50">
							
							<option value="">SELECT</option>
							{props.financialTerms.map((term) => (<option value={term.Id}>{term.Name}</option>))} 
							{/* <option value="1">Linked to Prime</option>
							<option value="3">Fixed</option>
							<option value="4">Linked to 3M JIBAR</option>
							<option value="11">Linked to LIBOR</option> */}
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive }  name="FacilityTermConditionsID" label="Term Conditions" className="mb-50">
							<option value="">SELECT</option>
							<option value="6">Revolving and Renewable Annually</option>
							<option value="11">Revolving and Renewable on Termination</option>
							<option value="12">Non-Revolving</option>
						</SelectField>
					</Col>
					<Col>
						<NumberField view={ view } required fast disabled={ adminWhileActive } name="FinancialPercentage" suffix="%" label="Financing Percentage" className="mb-50"></NumberField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive }  name="InterestCalculation" label="Interest Calculation" className="mb-50">
							<option value="">SELECT</option>
							<option value="Daily">Daily</option>
						</SelectField>
					</Col>
					<Col>
						<SelectField view={ view } required fast disabled={ nonUserWhileExpired } name="FacilityTermID" label="Term" className="mb-50">
							<option value="">SELECT</option>
							<option value="1">12 Months</option>
							<option value="2">24 Months</option>
							<option value="3">36 Months</option>
							<option value="4">48 Months</option>
							<option value="5">60 Months</option>
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={ view } required fast disabled={ adminWhileActive }  name="ProjectedFundingDays" label="Projected Funding Days" type="number" className="mb-50"></InputField>
					</Col>
					<Col>
						<SelectField view={ view } required name="CurrencyID" disabled={ adminWhileActive } label="Currency" className="mb-50">
							<option value="0">SELECT</option>
							{
								props.currencies && props.currencies.map(c =>
									<option key={ c.Id } value={c.Id}>{c.Abbreviation}</option>)
							}
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={ view } disabled={ adminWhileActive }  required={ formikContext.values.InterestCalculation === "Daily" } fast name="YearDays" label="Year Days" type="number"></InputField>
					</Col>
					<Col>
						<CheckboxField view={ view } disabled={ allWhileActive } name="AutoFinanceOption" label="Auto Finance"></CheckboxField>
					</Col>
				</Row>
				<FacilityFundersModal onFacilityFundersClicked={ handleFacilityFunderLimitsClicked } currency={ props.currency }
														linkedFunders={ formikContext.values.LinkedFunders } onClose={ e => handleFundersModalClosed() } show={showFundersModal}>
				</FacilityFundersModal>
				<FacilityFunderLimitHistoryModal 	show={ showFunderLimitsModal } funderIndex={ activeFunderIndex }
													funder={ (activeFunderIndex !== null ? formikContext.values.LinkedFunders[activeFunderIndex] : null) }
													currency={ props.currency }
													onClose={ e => { setShowFunderLimitsModal(false); setShowFundersModal(true);} }>
				</FacilityFunderLimitHistoryModal>
			</Panel>
}