import { DateTime } from "luxon";
import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FacilityTypes } from "../../../../Utilities/Enums";
import NumberValue from "../../../Core/NumberValue";
import ApprovalValue from "../ApprovalValue";

export default function Contact(props) {
	function getContactLabel() {
		if(props.paymentInstruction.FacilityTypeId === FacilityTypes.InvoiceDiscounting) {
			return "Customer";
		} else {
			return "Vendor";
		}
	}

	return 	<Row className="border py-1 mb-1">
				<Col>
					<Row className="mb-0">
						<ApprovalValue label={ `${getContactLabel()}` }>
							{ props.contact.CompanyName }
						</ApprovalValue>
						<ApprovalValue label="Accounting Code">
							{ props.contact.ContactAccountingCode }
						</ApprovalValue>
						<ApprovalValue label={ `${getContactLabel()} Limit` }>
							{
								props.contact.ContactLimit === 0 && <span>N/A</span>
							}
							{
								props.contact.ContactLimit > 0 && 
								<NumberValue currency={ props.paymentInstruction.CurrencySymbol } value={props.contact.ContactLimit  }></NumberValue>
							}
						</ApprovalValue>
						<ApprovalValue label={ `${getContactLabel()} Balance` }>
							{
								props.contact.ContactLimit === 0 && <span>N/A</span>
							}
							{
								props.contact.ContactLimit > 0 && 
								<NumberValue currency={ props.paymentInstruction.CurrencySymbol } value={props.contact.ContactBalance  }></NumberValue>
							}
						</ApprovalValue>
					</Row>
					{
						props.paymentInstruction.FacilityTypeId === FacilityTypes.TradeFinance &&
						<Row className="mb-0">
							<ApprovalValue label="Bank">
								{ props.contact.BankAccount.BranchName }
							</ApprovalValue>
							<ApprovalValue label="Branch Code">
								{ props.contact.BankAccount.BranchCode }
							</ApprovalValue>
							<ApprovalValue label="Account Number">
								{ props.contact.BankAccount.BankAccountNumber }
							</ApprovalValue>
						</Row>
					}
					<Row>
						<Col>
							<Table size="sm">
								<tbody>
									<tr className="border-top border-bottom-2">
										<td className="fw-bold">Invoice No.</td>
										<td className="fw-bold">Due Date</td>
										<td width="80" style={{ maxWidth: "80px" }} className="text-center fw-bold">Currency</td>
										<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">Document Amount</td>
										<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">VAT</td>
										<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">Document Total</td>
										<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">Payment Amount</td>
									</tr>
									{
										props.contact.Invoices.map(invoice =>
										<tr>
											<td>{ invoice.InvoiceNumber }</td>
											<td>{ DateTime.fromISO(invoice.DueDate).toFormat("dd/MM/yyyy") }</td>
											<td className="text-center">{ invoice.CurrencyAbbreviation }</td>
											<td className="text-end"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.DocumentAmount  }></NumberValue></td>
											<td className="text-end"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.VATAmount  }></NumberValue></td>
											<td className="text-end"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.DocumentTotal  }></NumberValue></td>
											<td className="text-end"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.PaymentAmount  }></NumberValue></td>
										</tr>)
									}
									{
										props.contact.InvoiceTotals.map((invoice, index) =>
										<tr>
											<td className="fw-bolder" colSpan={2}>{ (index === 0 ? "Totals" : "") }</td>
											<td className="text-center fw-bolder">{ invoice.CurrencyAbbreviation }</td>
											<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.DocumentAmount  }></NumberValue></td>
											<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.VATAmount  }></NumberValue></td>
											<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.DocumentTotal  }></NumberValue></td>
											<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.PaymentAmount  }></NumberValue></td>
										</tr>)
									}
									{
										(props.contact.InvoiceTotals.length > 1 || 
										(props.contact.InvoiceTotals.length === 1 && props.contact.InvoiceTotals[0].CurrencySymbol !== props.paymentInstruction.CurrencySymbol)) &&
										<tr>
											<td className="fw-bolder" colSpan={2}>Totals ({props.contact.FacilityInvoiceTotal.CurrencyAbbreviation} conversion)</td>
											<td className="text-center fw-bolder">{ props.contact.FacilityInvoiceTotal.CurrencyAbbreviation }</td>
											<td className="text-end fw-bolder"></td>
											<td className="text-end fw-bolder"></td>
											<td className="text-end fw-bolder"><NumberValue currency={ props.contact.FacilityInvoiceTotal.CurrencySymbol } value={props.contact.FacilityInvoiceTotal.DocumentTotal  }></NumberValue></td>
											<td className="text-end fw-bolder"><NumberValue currency={ props.contact.FacilityInvoiceTotal.CurrencySymbol } value={props.contact.FacilityInvoiceTotal.PaymentAmount  }></NumberValue></td>
										</tr>
									}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Col>
			</Row>
}