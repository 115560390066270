import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import StructureManager from "../../Utilities/StructureManager";
import { getPermissions, Permissions } from "../../Utilities/Permissions";
import BusyIndicator from "../Core/BusyIndicator";
import DateRange from "../Core/DateRange";
import { StructureDropDown } from "../Core/StructureFilter";
import ReportPreview from "./ReportsPreview";
import * as yup from "yup";
import { Formik, Form as FormikForm, FormikContext, useFormikContext } from "formik";
import SelectField from "../Core/Forms/SelectField";
import Select, { components } from "react-select";
import { DateTime } from "luxon";
import DateField from "../Core/Forms/DateField";

export default function ReportModal(props) {
	const store = useSessionStore();
	const initialValues = [{ value: -1, label: 'Select all' }]
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	const [groupId, setGroupId] = useState(0);
	const [marketPositionId, setMarketPositionId] = useState(0);
	const [storeId, setStoreId] = useState(0);
	const [vendor, setVendor] = useState([]);
	const [vendorId, setVendorId] = useState(0);
	const [paymentInstructionId, setPaymentInstructionId] = useState(0);
	const [assignableContacts, setAssignableContacts] = useState([]);
	const [paymentInstructions, setPaymentInstructions] = useState([]);
	const [selectContactIsDisabled, setSelectContactIsDisabled] = useState(true);
	const [facilities, setFacilities] = useState([]);
	const [facilityId, setFacilityId] = useState(0);
	const [facilityOptions, setFacilityOptions] = useState(initialValues);
	const [facilityOptionsAll, setFacilityOptionsAll] = useState([]);
	const [detailed, setDetailed] = useState(false);

	const structure = useRef(new StructureManager(store.Session.AuthState.UserAppStructure.AvailableStructure, true));
	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	var arrayFacilityValues = [];

	useEffect(() => {


		async function getAssignableFacilityContacts() {

			var results = await store.ReportsService.GetContacts();

			if (results && results.Success) {
				setAssignableContacts(results.Data.Contacts);
				setSelectContactIsDisabled(false);
			}
		}



		async function getPaymentInstructions() {

			var results = await store.ReportsService.GetPaymentInstructions();

			if (results && results.Success) {

				setPaymentInstructions(results.Data);
				setSelectContactIsDisabled(false);
			}
		}



		if (props.show) {
			if (props.Name === "Contact Payments Report") {
				getAssignableFacilityContacts();
			}
			else if (props.Name === "Payables Due Report" || props.Name === "Recoveries Report") {
				getPaymentInstructions();
			}
		}

	}, [props.Name, props.show, store.ReportsService]);

	async function getFacilities(groupId) {

		var result = await store.ReportsService.GetFacilities(groupId);

		if (result && result.Success) {

			var resultData = result.Data;
			setFacilityOptions(initialValues)
			setFacilityOptions(facilityOptions => facilityOptions.concat(resultData.map(item => ({
				value: item.Id,
				label: item.Number + " - " + item.Name
			}))));
			setFacilityOptionsAll(resultData.map(item => ({
				value: item.Id,
				label: item.Number + " - " + item.Name
			})));

			setFacilities([]);
		}

	}

	function setGroup(groupId) {
		structure.current.SetSelectedGroup(groupId);
		setGroupId(groupId);

		if (props.Name === "Facility Movement Report") {
			getFacilities(groupId);
		}

	}


	function setMarketPosition(marketPositionId) {
		structure.current.SetSelectedMarketPosition(marketPositionId);
		setMarketPositionId(marketPositionId);
	}

	function setStore(storeId) {
		structure.current.SetSelectedStore(storeId);
		setStoreId(storeId);
	}

	function handleSelectedContactChanged(value) {

		if (value !== "") {
			var contact = assignableContacts.find((contact) => contact.Id === parseInt(value));
			setPaymentInstructions(contact.vendorInstructions)
			setVendor(contact);
			setVendorId(contact.Id);
		} else {
			setVendor(null);
			setVendorId(null);
		}
	}

	function handleSelectedPiChanged(value) {
		if (value !== "") {
			var contact = paymentInstructions.find((contact) => contact.Id === parseInt(value));
			setPaymentInstructionId(contact.Id)

		} else {
			setPaymentInstructionId(null)
		}
	}

	var handleSelectedFacility = (facilities) => {


		if (facilities.find(x => x.value === -1)) {
			setFacilities(facilityOptionsAll)
		}
		else {

			setFacilities(facilities);
		}
	};
	function getFacilitiesIds() {
		for (let i = 0; i < facilities.length; i++) {
			arrayFacilityValues.push(facilities[i].value);
		}

		return arrayFacilityValues;
	}

	function onClose() {
		setSelectContactIsDisabled(true);
		setVendor(null);
		setFromDate(null);
		setToDate(null);
		setPaymentInstructionId(null);
		setPaymentInstructions([]);
		setGroupId(null);
		setGroup("");
		setMarketPosition("");
		setStore("");
		setFacilities([]);


		props.onClose()
	}

	function handleDetails(detailed) {

		if (detailed === "D") {
			setDetailed(true);
		} else {
			setDetailed(false);
		}
	};

	const validationSchema = yup.object({
		SelectContact: yup.string().required(),
		SelectPI: yup.string().required(),
		SelectPayInstruction: yup.string().required(),
		SelectFacility: yup.string().required(),
		ViewPreference: yup.string().required(),
		SelectGroup: yup.string().required(),
		FromDate: yup.string().required(),
		ToDate: yup.string().required()
	}).required();

	const CustomDropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="15"  // Adjust the width here
					height="15" // Adjust the height here
					viewBox="0 0 20 20"
				>
					<path
						d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
						fill="black"
						stroke="white"
					/>
				</svg>
			</components.DropdownIndicator>
		);
	};

	function handleRunReport() {

		// permissions.Any() &&
		// <ReportPreview toDate={toDate} fromDate={fromDate} groupId={groupId} marketPositionId={marketPositionId} storeId={storeId} name={props.Name} vendorId={vendor} piId={paymentInstructionId} facilitiesId={getFacilitiesIds()} detailed={detailed}></ReportPreview>				
	}


	return <Modal size="lg" enforceFocus={false} show={props.show}>
		<Formik initialValues={{ SelectContact: null, SelectPI: null, SelectPayInstruction: null, SelectFacility: null, ViewPreference: null, SelectGroup: null }} validateOnMount validationSchema={validationSchema} onSubmit={handleRunReport}>
			<FormikForm>
				<Modal.Header style={{ backgroundColor: '#38a7df' }}>
					<h4 style={{ color: 'white', fontWeight: '300' }}>
						{props.Name}
					</h4>
				</Modal.Header>
				<Modal.Body >
					{
						<React.Fragment>
							{
								props.Name === "Maturity Date Report" &&
								<Form className="mb-50">
									<DateRange autoFocus fromDate={fromDate} toDate={toDate} required maxDate={DateTime.now()}
										onFromChange={e => { setFromDate(e) }}
										onToChange={e => { setToDate(e) }} maturityDateRange />

									<StructureDropDown label="Group" value={structure.current.GroupId} structure={structure.current.Groups} onChange={setGroup}></StructureDropDown>
									<StructureDropDown allowAll label="Market Position" value={structure.current.MarketPositionId} structure={structure.current.MarketPositions} onChange={setMarketPosition}></StructureDropDown>
									<StructureDropDown allowAll label="Store" value={structure.current.StoreId} structure={structure.current.Stores} onChange={setStore}></StructureDropDown>

								</Form>
								|| props.Name === "Contact Payments Report" &&
								<React.Fragment>
									{
										selectContactIsDisabled === true &&
										<div className="d-flex w-100 h-100 justify-content-center align-items-center">
											<BusyIndicator size="3x" show></BusyIndicator>
										</div>
									}
									{selectContactIsDisabled === false &&
										<React.Fragment>
											<SelectField className="mb-50" name="SelectContact" label="Contact" value={vendorId} required onChange={e => handleSelectedContactChanged(e.target.value)}>
												<option value="">Select Contact</option>
												{
													assignableContacts.map((value) =>
														<option key={value.Id} value={value.Id}>{value.Name}</option>)
												}
											</SelectField>
											<SelectField className="mb-50" name="SelectPI" label="Payment Instruction" value={paymentInstructionId} required onChange={e => handleSelectedPiChanged(e.target.value)}>
												<option value="">Select Payment Instruction</option>
												{
													paymentInstructions.map((value) =>
														<option value={value.Id}>{value.piNumber} - {value.Name}</option>
													)
												}
											</SelectField>
										</React.Fragment>
									}
								</React.Fragment>
								|| (props.Name === "Payables Due Report" || props.Name === "Recoveries Report") &&
								<React.Fragment>
									{
										selectContactIsDisabled === true &&
										<div className="d-flex w-100 h-100 justify-content-center align-items-center">
											<BusyIndicator size="3x" show></BusyIndicator>
										</div>
									}
									{
										selectContactIsDisabled === false &&
										<SelectField name="SelectPayInstruction" label="Payment Instruction" value={paymentInstructionId} required onChange={e => handleSelectedPiChanged(e.target.value)} disabled={selectContactIsDisabled}>
											<option value="">Select Payment Instruction</option>
											{
												paymentInstructions.map((value) =>
													<option value={value.Id}>{value.piNumber} - {value.Name}</option>)
											}
										</SelectField>
									}
								</React.Fragment>
								|| (props.Name === "Facility Movement Report") &&
								<React.Fragment>

									<Row className="mb-50 pe-0">
										<DateField className="px-0 ps-1 pe-0" label="From" name="FromDate" required col={9} fmr onChange={e => { setFromDate(e) }}></DateField>
									</Row>
									<Row className="mb-50 pe-0">
										<DateField className="px-0 ps-1 pe-0" label="To" name="ToDate" required col={9} fmr maxDate={DateTime.fromISO(DateTime.now()).toJSDate()} onChange={e => { setToDate(e) }}></DateField>
									</Row>


									<StructureDropDown label="Group" value={structure.current.GroupId} invalid={structure.current.GroupId === ""} structure={structure.current.Groups} onChange={setGroup} required></StructureDropDown>
									<Row className="mb-50">
										<Col xs={3}>
											<label style={{ marginTop: 10 }}>Facilities</label>
										</Col>
										<Col>
											<Select options={facilityOptions} isMulti value={facilities} name="SelectFacility" onChange={handleSelectedFacility} className={facilities.length === 0 ? "form-control is-invalid p-0" : "form-control p-0"} placeholder="---Select---" components={{ DropdownIndicator: CustomDropdownIndicator }} styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: "white", "&:hover": { borderColor: "white" } }), dropdownIndicator: (baseIndicator) => ({ ...baseIndicator, paddingRight: 14 }), placeholder: (basePlaceholder) => ({ ...basePlaceholder, color: "black" }) }}></Select>
										</Col>
									</Row>
									<SelectField name="ViewPreference" label="View Preference" required col={9} className="mb-50" onChange={e => handleDetails(e.target.value)}>
										<option value="">---Select---</option>
										<option value="D">Detailed</option>
										<option value="S">Summary</option>
									</SelectField>

								</React.Fragment>
							}
						</React.Fragment>
					}

				</Modal.Body>
				<Modal.Footer>
					{
						permissions.Any() &&
						<ReportPreview toDate={toDate} fromDate={fromDate} groupId={groupId} marketPositionId={marketPositionId} storeId={storeId} name={props.Name} vendorId={vendor} piId={paymentInstructionId} facilitiesId={getFacilitiesIds()} detailed={detailed}></ReportPreview>
					}
					<Button onClick={() => onClose()}>Cancel</Button>
				</Modal.Footer>
			</FormikForm>
		</Formik>
	</Modal>
}