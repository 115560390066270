import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { faBellExclamation } from "@fortawesome/pro-regular-svg-icons";
import { useSessionStore } from "../../../../Stores/SessionStore";
import PageHeader from "../../../Core/PageHeader";
import Panel from "../../../Core/Panel";
import BusyIndicator from "../../../Core/BusyIndicator";
import ClickableText from "../../../Core/ClickableText";
import NotificationContentModal from "./NotificationContentModal";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";
import Moment from "moment";

export default function NotificationsIndex(props) {

	UseDocumentTitle('Notifications');

	const store = useSessionStore();
	
	const [notifications, setNotifications] = useState(null);



	const [selectedNotificationId, setSelectedNotificationId] = useState(null);
	const [showContentModal, setShowContentModal] = useState(false);

	const [markingAsRead, setMarkingAsRead] = useState(false);

	async function getNotifications() {
		setNotifications(null);
		let result = await store.MessagesService.GetUnreadMessages();

		if(result && result.Success) {
			setNotifications(result.Data);
		}
	}

	useEffect(() => {
		getNotifications();
	}, []);

	async function handleMarkAsReadClicked(messageId) {
		setSelectedNotificationId(messageId);
		setMarkingAsRead(true);

		var result = await store.MessagesService.MarkMessageAsRead(messageId);

		setMarkingAsRead(false);
		setSelectedNotificationId(null);

		if(result && result.Success) {
			getNotifications();
			store.GetHasNotifications();
		}
	}

	function handleViewMessageClicked(messageId) {
		setSelectedNotificationId(messageId);
		setShowContentModal(true);
	}

	function handleViewMessageModalClosed() {
		setShowContentModal(false);
		setSelectedNotificationId(null);
	}

	function getMessageType(message) {
		switch (message.Type) {
			case 0:
				return "Payable Transaction";
			case 1:
				return "Debt Transaction";
			case 2:
				return "Payment Instruction";
			case 3:
				return "Facility Fixed Rate Expiration";
			default:
				return "";
		}
	}

	return 	<React.Fragment>
				<PageHeader icon={ faBellExclamation } title={ "Notifications" }  helpItemDescriptor={ "Account/Notifications" }
							crumbs={[{ Title: "Notifications", Active: true }]}>
				</PageHeader>
				<Panel title="Notifications">
					<Table size="sm" responsive>
						<thead>
							<tr>
								<th>Date</th>
                                <th>Type</th>
                                <th>Document Number</th>
								<th>Message</th>
                                <th className="col-2 text-center">Actions</th>
                            </tr>
						</thead>
						<tbody>
							{
								notifications &&
								notifications.map((message, index) =>
									<tr key={ index }>
										<td>{ Moment( message.DateCreated).format("DD/MM/YYYY")}</td>
										<td>{ getMessageType(message) }</td>
										<td>{ message.DocumentNumber }</td>
										<td>{ message.Subject }</td>
										<td className="text-center">
											{
												message.Url &&
												<a href={ `${message.Url}&messageId=${message.Id}` } target="_blank" rel="noreferrer">Respond</a>
											}
											{
												!message.Url &&
												<div>
													{ 
														(selectedNotificationId !== message.Id || (selectedNotificationId === message.Id && markingAsRead === false)) &&
														<div>
															<span><ClickableText onClick={ e => handleViewMessageClicked(message.Id) }>View Message</ClickableText></span>
															<span> | </span>
															<span><ClickableText onClick={ e => handleMarkAsReadClicked(message.Id) }>Mark as Read</ClickableText></span>
														</div>
													}
													{ 
														selectedNotificationId === message.Id && markingAsRead === true &&
														<BusyIndicator show={true} size="1x"></BusyIndicator>
													}
												</div>
											}
										</td>										
									</tr>)
							}
						</tbody>						
					</Table>
					<BusyIndicator show={ notifications === null }></BusyIndicator>
				</Panel>
				<NotificationContentModal messageId={ selectedNotificationId } show={ showContentModal } onClose={ handleViewMessageModalClosed }></NotificationContentModal>
			</React.Fragment>
}