import {View, Text} from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import moment from "moment";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function FacilityMovementTransactions(props){

    

    return(
        <View style={{paddingBottom:10}}>
            <View style={[styles.tableRowWrapping, {paddingTop:3, paddingBottom:3}]}>
                <Text style={[styles.textHeaders,{textAlign:'left', width:( props.detailed ? '8%' : '12%' )}]}>Date</Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:'12%'}]}>Facility No.</Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:'15%'}]}>Batch Reference</Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:( props.detailed ? '6%' : '' ), paddingLeft:5}]}>{props.detailed ? "Type" : ""}</Text>
                
                <Text style={[styles.textHeaders,{textAlign:'left', width:'12%', paddingLeft:5}]}>{props.detailed ? "Document No." : ""} </Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:( props.detailed ? '11%' : '13%' )}]}>{props.detailed ? "Contact Name" : ""}</Text>

                <Text style={[styles.textHeaders,{textAlign:'right', width:'12%'}]}>Debit</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'12%'}]}>Credit</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'12%'}]}>Balance</Text>
            </View>
            <View style={styles.tableRowWrapping} wrap={false}>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '8%' : '12%' )}]}>{moment(props.openingDate).format("DD/MM/YYYY")}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:'12%', overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}></Text>
                <Text style={[styles.textData,{textAlign:'left', width:'15%', overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}>Opening Balance</Text>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '6%' : '' ), paddingLeft:5}]}></Text>
                <Text style={[styles.textData,{textAlign:'left', width:'12%', overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}></Text>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '11%' : '13%' ), overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}></Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}></Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}></Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{formatCurrencyValue(props.data.Currency,props.data.OpeningBalance)}</Text>
            </View>
            {props.data.Facilties.map(value =>
            <View style={styles.tableRowWrapping} wrap={false}>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '8%' : '12%' )}]}>{moment(value.DateTime).format("DD/MM/YYYY")}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:'12%', overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}>{value.Number}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:'15%', overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}>{value.BatchReference}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '6%' : '' ), paddingLeft:5, overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}>{props.detailed ? value.Type : ""}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:'12%', paddingLeft:5, overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}>{value.DocumentNumber}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '11%' : '13%' ), overflow:'hidden', textOverflow:'ellipsis', maxLines: 1}]}>{value.ContactName}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{ value.Debit ? formatCurrencyValue(value.Currency, value.Debit) : ""}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{ value.Credit ? formatCurrencyValue(value.Currency, value.Credit) : ""}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{formatCurrencyValue(value.Currency,value.Balance)}</Text>
            </View>
            
            )}
        </View>
    )
}