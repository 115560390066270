import { Field, useFormikContext } from "formik";
import React from "react";
import Documents from "../../../../Core/Documents/Documents";
import CheckboxField from "../../../../Core/Forms/CheckboxField";
import ImageUploadInput from "../../../../Core/Forms/ImageUploadInput";
import Panel from "../../../../Core/Panel";
import TextValue from "../../../../Core/TextValue";
import { ContactableEntityTypes } from "./ContactableEntity";

export default function AdditionalDetails(props) {
	const formikContext = useFormikContext();

	function handleFileChanged(file, base64String) {
		let values = formikContext.values;

		if(file) {
			values.LogoFileName = file.name;
			values.LogoMIMEType = file.type;
		}
		values.LogoBase64 = base64String;

		values.LogoChanged = true;

		formikContext.setValues(values);
	}

	function handleDocumentUpdate(values) {
		formikContext.setValues(values);
	}	

	return 	<React.Fragment>
				<Panel title="Comments">
					{
						!props.view &&
						<Field name="Comments">
							{ ({ field }) => ( <textarea className="form-control" { ...field }></textarea> ) }
						</Field>
					}
					{
						props.view &&				
						<TextValue value={ formikContext.values["Comments"] }></TextValue>
					}
				</Panel>
				<Panel title="Uploads">
					<ImageUploadInput 	onFileChanged={ handleFileChanged }
										base64={ formikContext.values.LogoBase64 } 
										mime={ formikContext.values.LogoMIMEType } 
										name={ formikContext.values.LogoFileName } 
										label="Logo" 
										view={props.view}>
					</ImageUploadInput>
				</Panel>
				{
					formikContext.values.Id &&
					<Documents updateEntity={ handleDocumentUpdate } viewOnly={ props.view } allowDocChanges entity={ formikContext.values }></Documents>
				}
				
					
					<Panel title="Active">
						<CheckboxField col={10} view={props.view} name="Active" de label="Active"></CheckboxField>
					</Panel>
				
			</React.Fragment>
}