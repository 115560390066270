import { faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { hasControl, Roles } from "../../../../Utilities/Permissions";
import NumberValue from "../../../Core/NumberValue";
import Panel from "../../../Core/Panel";
import swal  from "sweetalert"
import CreatePaymentRecovery from "./CreatePaymentRecoveryModal";
import AssignRecoveriesModal from "./AssignRecoveries/AssignRecoveriesModal";
import OutstandingExportModal from "./OutstandingExportModal";
import DateValue from "../../../Core/DateValue";
import { getStatusText } from "../../../../Utilities/PaymentInstructions";
import BusyIndicator from "../../../Core/BusyIndicator";
import IconButton from "../../../Core/IconButton";

export default function RecoveryStateTable(props) {
	const store = useSessionStore();
	const [recoveryData, setRecoveryData] = useState({});

	const [recoverPaymentModalShown, showRecoverPaymentModal] = useState(false);
	const [assignRecoveriesModalShown, showAssignRecoveriesModal] = useState(false);
	const [outstandingExportModalShown, showOutstandingExportModal] = useState(false);

	const [importData, setImportData] = useState(null);

	function handleCreateRecoveryClosed () {        
		showRecoverPaymentModal(false);
		props.onRefresh();
	}

	function handleAssignRecoveriesClosed (update) {        
		showAssignRecoveriesModal(false);

		if(update) {
			props.onRefresh();

			store.PaymentManagementStore.GetPaymentInstructions();
			store.PaymentManagementStore.GetPaymentProcessingInstructions();
			store.PaymentManagementStore.GetPaymentRecoveries();
			store.PaymentManagementStore.GetReimbursements();
		}
	}

	function handleOutstandingImportClosed() {
		showOutstandingExportModal(false);
		props.onRefresh();
	}

	function handleCreateRecoverySaveClicked (recoveryData) {
		setRecoveryData(recoveryData);
		showRecoverPaymentModal(false);
		showAssignRecoveriesModal(true);
	}

	function handleRecoverPaymentClicked () {        
		if(props.state.ActiveExport) {
			if(props.state.ActiveExport.UserId === store.Session.UserId) {
				//the current user is responsible for this export, show the outstanding export screen
				showOutstandingExportModal(true);
			}
		}
		else {
			setRecoveryData({});
			setImportData(null);
			showRecoverPaymentModal(true)
		}    
	}

    function handleImportDiscarded() {
		props.onImportDiscarded();
		setImportData(null);
        setRecoveryData({});
        showOutstandingExportModal(false);
        showRecoverPaymentModal(true);
    }

	async function deleteActiveRecoveryExport() {
		let result = await store.PaymentInstructionRecoveriesService.DeleteActiveRecoveryExport(props.state.PaymentInstructionId);

		if(result && result.Success) {
			handleImportDiscarded();
		} else {
			swal("Discard error", "An error occurred attempting to discard the export.", "error");
		}
	}

	function handleUnlockExportClicked () {
		swal({
			icon: "warning",
			title: "Unlock Recovery",
			text: "Unlocking this Recovery Export will discard it. The User that originally exported the recovery may no longer be able to import their worksheet, continue?",
			closeOnConfirm: true,
			buttons: {
				cancel: true,
				confirm: true
			}, 
			closeOnClickOutside: false
		}).then(val => {
			if(val) {
				deleteActiveRecoveryExport();
			}
		});
	}

	function handleRecoveryImported(data, activeExport) {
		setImportData(data);
		setRecoveryData({ DateReceived: activeExport.DateReceived, AllocatedAmount: activeExport.RecoveryAmount, TheoreticalAmount: activeExport.TheoreticalRecoveryAmount })
		showOutstandingExportModal(false);
		showAssignRecoveriesModal(true);
	}

	const canUnlockExport = store.Session.Role === Roles.Admin || store.Session.AuthState.UserAppStructure.GroupAdminLevel || hasControl("Unlock Recovery", store.Session);

    return <React.Fragment>
            <React.Fragment>
                <Panel title="Payment Recoveries">
				{  
					props.state &&
                    <Table bordered size="sm" responsive>
                        <thead>
                        <tr>
                            <th>Maturity Date</th>
                            <th className="no-truncation">Payment Instruction No.</th>
                            <th className="text-end">Document Total</th>
                            <th className="text-end">Payment Amount</th>
                            <th className="text-end">Interest</th>
                            <th className="text-end no-truncation">Projected Funding Amount</th>
                            <th>Status</th>
                            <th>Recovery Details</th>
                        </tr>
                    </thead>
                        <tbody>
                            <tr>
                                <td style={{ verticalAlign: "top", maxWidth: "none" }}><DateValue date={props.state.MaturityDate}></DateValue></td>
                                <td style={{ verticalAlign: "top", maxWidth: "none" }}>{ props.state.PaymentInstructionNumber }</td>
                                <td className="text-end" style={{ verticalAlign: "top" }}><NumberValue value={ props.state.Amount} currency={ props.state.CurrencySymbol} /></td>
                                <td className="text-end" style={{ verticalAlign: "top" }}><NumberValue value={ props.state.PaymentAmount} currency={ props.state.CurrencySymbol} /></td>
                                <td className="text-end" style={{ verticalAlign: "top" }}><NumberValue value={ props.state.ProjectedInterest} currency={ props.state.CurrencySymbol} /></td>
                                <td className="text-end" style={{ verticalAlign: "top" }}><NumberValue value={ props.state.FundingTotal} currency={ props.state.CurrencySymbol} /></td>
                                <td style={{ verticalAlign: "top" }}>
                                    <span>{ getStatusText(props.state) }</span>
                                </td>
                                <td style={{ verticalAlign: "top", maxWidth: "none" }}>
                                    <Table borderless  size="sm">
                                        <tbody>
                                            {
                                                props.state.AllocatedPayments.map((value, index) =>
                                                <tr key={ index }>
                                                        <td><DateValue date={value.Timestamp}></DateValue></td>
                                                        <td className="text-end"><NumberValue value={value.Amount} currency={props.state.CurrencySymbol} /></td>
                                                    </tr>
                                                )
                                            }
                                            
                                            <tr className="border-top">
                                                <td><b>Actual Received</b></td>
                                                <td className="text-end"><b><NumberValue value={props.state.TotalAllocated - props.state.TotalAdjusted} currency={ props.state.CurrencySymbol} /></b></td>
                                            </tr>
                                            <tr>
                                                <td><b>{ props.state.RemainingAmount < 0 ? "Unallocated" : "Outstanding"}</b></td>
                                                <td className={ "text-end " + ( props.state.IsOverRecovered ? "text-danger" : ( props.state.IsFullyRecovered ? "text-success" : "")) }>
													<b>
                                                    	<NumberValue value={ props.state.RemainingAmount} currency={ props.state.CurrencySymbol} />
													</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>   
                                    {
                                        props.allowRecoveries &&
                                        <div className="text-center mt-2">
                                            {
                                                (props.state.IsFullyRecovered === false && (!props.state.ActiveExport || (props.state.ActiveExport && props.state.ActiveExport.UserId === store.Session.UserId))) &&
                                                <IconButton onClick={handleRecoverPaymentClicked} icon={ faMoneyBill }>Recover Payments</IconButton>
                                            }

                                            {
                                                (props.state.IsFullyRecovered === false && (props.state.ActiveExport && props.state.ActiveExport.UserId !== store.Session.UserId) && canUnlockExport === false) &&
                                                <IconButton title={"Locked for editing by " + props.state.RecoveryExportUsername}  icon={ faLock } disabled>Recover Payments</IconButton>
                                            }

                                            {
                                                (props.state.IsFullyRecovered === false && (props.state.ActiveExport && props.state.ActiveExport.UserId !== store.Session.UserId) && canUnlockExport) &&
                                                <IconButton title={"Locked for editing by " + props.state.RecoveryExportUsername} onClick={handleUnlockExportClicked} icon={ faLock }>Recover Payments</IconButton>
                                            }
                                        </div>
                                    }                                    
                                </td>
                            </tr>
                        </tbody>
                    </Table>
					}
					<BusyIndicator show={ !props.state }></BusyIndicator>
                </Panel>
				{
					props.state &&
					<React.Fragment>
						<CreatePaymentRecovery show={ recoverPaymentModalShown } paymentInstruction={ props.state } onSaveClicked={ handleCreateRecoverySaveClicked }
									onCloseClicked={ handleCreateRecoveryClosed }></CreatePaymentRecovery>
				
						<AssignRecoveriesModal show={ assignRecoveriesModalShown } onCloseClicked={ handleAssignRecoveriesClosed } 
						recoveryData={ recoveryData } paymentInstruction={ props.state } importData={ importData }></AssignRecoveriesModal>

						<OutstandingExportModal show={ outstandingExportModalShown } paymentInstruction={ props.state }
						onImportDiscarded={ handleImportDiscarded } onRecoveryImported={ handleRecoveryImported } onCloseClicked={ handleOutstandingImportClosed }></OutstandingExportModal>
					</React.Fragment>
				}
			
            </React.Fragment>
			
        </React.Fragment>
}