import React from 'react';
import ReactDOM from 'react-dom';

import "./Vuexy/css/bootstrap.min.css";
import "./Vuexy/css/bootstrap-extended.min.css";
import "./Vuexy/css/colors.min.css";
import "./Vuexy/css/components.min.css";

import "./Vuexy/css/themes/dark-layout.min.css";
import "./Vuexy/css/themes/bordered-layout.min.css";
import "./Vuexy/css/themes/semi-dark-layout.min.css";

import "./Vuexy/css/core/menu/vertical-menu.min.css";

import "./Vuexy/css/style.css";

import App from './Components/App';
import { SessionStoreProvider } from './Stores/SessionStore';
import { Font } from '@react-pdf/renderer';

Font.register({ family: 'Roboto', 
				fonts: [{ src: "./fonts/Roboto-Regular.ttf" },
						{ src: "./fonts/Roboto-Bold.ttf", fontWeight: "bold"}] });

Font.registerHyphenationCallback((word) => [word]); //instruct react-pdf to not hyphenate words

fetch("./config.json").then(async (response) => {
    if(response.ok) {
        var config = await response.json();

        ReactDOM.render(
            <React.StrictMode>
                <SessionStoreProvider config={ config }>
                    <App></App>
                </SessionStoreProvider>
            </React.StrictMode>,

            document.getElementById('root')
        );
    }
});