import { DateTime } from "luxon";
import swal from "sweetalert";
import * as yup from 'yup';
import { FacilityConditions, UserRoles } from "./Enums";
import { getRateForFinancialTerm } from "./FinancialTerms";
import { GetRandomKey } from "./React";
import { hasControl, Permissions } from "../Utilities/Permissions";


/**
 * Gets the latest active funder limit for the given funder
 */
export function getCurrentFunderLimit(funder) {
	var activeLimits = funder.LimitHistory.filter((l) => l.Active === true);

	activeLimits.sort((a, b) => {
		var aDate = DateTime.fromISO(a.Date);
		var bDate = DateTime.fromISO(b.Date);

		var result = bDate - aDate;

		return result;
	});



	let limit = activeLimits.reduce((total, object) => { 
		if(object.Active === true && DateTime.fromISO(object.Date).ts <= DateTime.now()) {
		total = total + object.Limit
	} 
		return total;
	}, 0)

	return (limit);
}

export function getCurrentRate(rates) {
	var today = Date.now()
	var activeRates = rates.filter((r) => r.Active === true && DateTime.fromISO(r.StartDate) <= today);

	activeRates.sort((a, b) => {
		var aDate = DateTime.fromISO(a.StartDate);
		var bDate = DateTime.fromISO(b.StartDate);

		var result = bDate - aDate;

		return result;
	});

	return (activeRates[0] ? activeRates[0].Rate : 0);
}

export const FacilityValidationSchema =
	yup.object({
		CurrencyID: yup.mixed().not([ 0, "0" ]),
		FacilityNumber: yup.string().required(),
		Name: yup.string().required(),
		FacilityTypeID: yup.mixed().not([ 0, "0" ]),
		FacilityConditionsID: yup.string().not([ 0, "0" ]),
		FacilityTermID: yup.mixed().not([ 0, "0" ]),
		FacilityTermConditionsID: yup.mixed().not([ 0, "0" ]),
		InterestCalculation: yup.string().required(),
		ProjectedFundingDays: yup.mixed().required(),
		AccountNumber: yup.string().required(),
		FinancialTermId: yup.mixed().not([ 0, "0" ]),
		FinancialPercentage: yup.mixed().required(),
		AdvanceDate: yup.string().required(),
		TerminationDate: yup.string().required(),
		FacilityFee: yup.mixed().required(),
		RenewalFee: yup.mixed().required(),
		ServiceFee: yup.mixed().required(),
		DocumentFee: yup.mixed().required(),
		StructureFee: yup.mixed().required(),
		OtherFees: yup.mixed().required(),
		TradingFee: yup.mixed().required(),
		PlatformFee: yup.mixed().required(),
		IssuingCost: yup.mixed().required(),
		LegalCost: yup.mixed().required(),
		CollateralManagementCost: yup.mixed().required(),
		InsuranceCost: yup.mixed().required(),
		OtherCosts: yup.mixed().required(),
		TradingDiscountSplitNumerator:  yup.mixed().required(),
		TradingDiscountSplitDenominator: yup.mixed().required(),
		FixedBaseRateExpiryDate: yup.mixed().test({ test: (value, ctx) => !ctx.parent.UseFixedBaseRate || (ctx.parent.UseFixedBaseRate && value !== undefined) }),
		YearDays: yup.mixed().test({ test: (value, ctx) => ctx.parent.InterestCalculation !== "Daily" ||
															(ctx.parent.InterestCalculation === "Daily" && value !== undefined) }),
		Securities: yup.array().test({ test: (securities) => 	securities.length > 0 &&
																securities[0].Field && securities[0].Field.length > 0 &&
																securities[0].Value && securities[0].Value.length > 0 }),
		Covenants: yup.array().test({ test: (covenants) => 		covenants.length > 0 &&
																covenants[0].Field && covenants[0].Field.length > 0 &&
																covenants[0].Value && covenants[0].Value.length > 0 })
}).required();

export function getNewFacility(preferredCurrencyId) {
	return {
		FacilityStatusID: 1, Comment: "", CurrencyID: preferredCurrencyId, FacilityNumber: "", Name: "", AccountNumber: "", InterestCalculation: "",
        FinancialTermId: 0, FacilityTypeID: 0, FacilityConditionsID: 0, FacilityTermID: 0, FacilityTermConditionsID: 0,
		ProjectedFundingDays: "", AdvanceDate: "", TerminationDate: "", CostFinancePercentage: "", FacilityFee: "", RenewalFee: "",
		UnderutilisationFee: 0, ServiceFee: "", DocumentFee: "", IssuingCost: "", LegalCost: "", CollateralManagementCost: "",
		InsuranceCost: "", FinancialPercentage: "", StructureFee: "", OtherCosts: "", Factor: "", OtherFees: "", TradingFee: "",
		TradingDiscountSplitNumerator: "", TradingDiscountSplitDenominator: "", PlatformFee: "", LinkedTrading: false, YearDays: "",
		OffTakerContactId: "", UseFixedBaseRate: false, FixedBaseRateExpiryDate: "", AutoFinanceOption: false, PayableTransactionApprovalUserOne: "",
		PayableTransactionApprovalUserTwo: "", PayableTransactionVerificationUserOne: "", PayableTransactionVerificationUserTwo: "",
		PaymentInstructionConfirmationUserOne: "", PaymentInstructionConfirmationUserTwo: "", PaymentInstructionAuthorisationUserOne: "",
		PaymentInstructionAuthorisationUserTwo: "", DebtTransactionApprovalUserOne: "", DebtTransactionApprovalUserTwo: "",
		DebtTransactionVerificationUserOne: "", DebtTransactionVerificationUserTwo: "",
		Securities: [ { Field: "", Value: "", Key: GetRandomKey()}, { Field: "", Value: "", Key: GetRandomKey() } ],
		Covenants: [ { Field: "", Value: "", Key: GetRandomKey() }, { Field: "", Value: "", Key: GetRandomKey() } ],
		FacilityFeesFrequencies: {
			FacilityFeeFrequency: 0, StructuringFeeFrequency: 0, RenewalFeeFrequency: 0, InsuranceCostFrequency: 0, ServiceFeeFrequency: 0,
			LegalCostFrequency: 0, PlatformFeeFrequency: 0, IssuingCostFrequency: 0, CollateralManagementCostFrequency: 0, TradingFeeFrequency: 0,
			DocumentFeeFrequency: 0, OtherFeeFrequency: 0, OtherCostsFrequency: 0
		},
		LinkedFunders: [], BaseInterestRates: [], CostofFinanceFundersMargins: [], CostofFinanceTradingMargins: [], UnderutilisationFundersMargins: [],
		UnderutilisationTradingMargins: [], Limit: 0,CurrencySymbol:""
	}
}

export function getTotalMargins(fundersMargins, tradingMargins, useFixedBaseRate, baseInterestRates, financialTerms, financialTermId) {
	var total = getCurrentRate(fundersMargins) + getCurrentRate(tradingMargins);

	if(useFixedBaseRate) {
		total += getCurrentRate(baseInterestRates);
	} else {
		var rate = getRateForFinancialTerm(financialTerms, financialTermId);

		if(rate) {
			total += rate;
		}
	}

	return total;
}

function hasInterestEndDateBeenUpdated(oldFacility, newFacility) {
	let oldEndDate = DateTime.fromISO(oldFacility.FixedBaseRateExpiryDate);
	let newEndDate = DateTime.fromISO(newFacility.FixedBaseRateExpiryDate);

	if(newEndDate.invalidReason) {
		return false;
	}

	if(oldEndDate.invalidReason && !newEndDate.invalidReason) {
		return true;
	}

	return oldEndDate.diff(newEndDate, "days").days !== 0;
}

/**
 * returns whether or not the base interest rate end date has been updated if the base interest rates have changed
 * @param {any} oldFacility 
 * @param {any} newFacility 
 */
export function validateBaseInterestRateChanges(oldFacility, newFacility) {
	let oldRates = oldFacility.BaseInterestRates;
	let newRates = newFacility.BaseInterestRates;

	let rateEndDateUpdated = hasInterestEndDateBeenUpdated(oldFacility, newFacility);

	if(!oldRates && !newRates) {
		return true;
	}

	if((!oldRates && newRates) || (oldRates && !newRates))  {
		return rateEndDateUpdated;
	}

	if(oldRates.length !== newRates.length) {
		return rateEndDateUpdated;
	}

	if(oldRates.filter(r => r.Active).length !== newRates.filter(r => r.Active).length) {
		return rateEndDateUpdated;
	}

	return true;
}

/**
 * Check if any of the COF and UU margins total to 0% and show a message if true
 */
export async function checkMarginsForZeroes(values) {

	const uuRequired = parseInt(values.FacilityConditionsID) !== FacilityConditions.InterestAccruingOnDrawnPortionsOnly;

	var cofTradingMargins = getCurrentRate(values.CostofFinanceTradingMargins);
	var cofFunderMargins = getCurrentRate(values.CostofFinanceFundersMargins);
	var uuFeeFunderMargins = getCurrentRate(values.UnderutilisationFundersMargins);
	var uuFeeTradingMargins = getCurrentRate(values.UnderutilisationTradingMargins);
	var fixedBaseRate = getCurrentRate(values.BaseInterestRates);

	var showMessage = (cofTradingMargins === 0.0 || cofFunderMargins === 0.0 ||
						(uuRequired && (uuFeeFunderMargins === 0.0 || uuFeeTradingMargins === 0.0)) ||
						(fixedBaseRate === 0.0 && values.UseFixedBaseRate));

	if(showMessage) {
		var errorMessage = 	`<div>
								<p>You are about to create a new Facility but the following fields are currently set to 0%</p>
								<p>
								${ ((fixedBaseRate === 0.0 && values.UseFixedBaseRate) ? "<div> - Fixed Base Rate of Interest</div>" : "") }
								${ ((cofTradingMargins === 0.0) ? "<div> - Cost of Finance: Trading Margin</div>" : "") }
								${ ((cofFunderMargins === 0.0) ? "<div> - Cost of Finance: Funders Margin</div>" : "") }
								${ ((uuFeeFunderMargins === 0.0 && uuRequired)? "<div> - Underutilisation: Funders Margin</div>" : "") }
								${ ((uuFeeTradingMargins === 0.0 && uuRequired) ? "<div> - Underutilisation: Trading Margin</div>" : "") }
								</p>
								<p>Click OK to save the new Facility anyway or Cancel to go back</p>
							</div>`

		let parser = new DOMParser();
		let content = parser.parseFromString(errorMessage, "text/html").body.firstChild;
		
		return swal({ 
			icon: "warning", 
			title: "Fields are currently zero", 
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					closeModal: true,
			},
				confirm: {
					text: "OK",
					value: true,
					visible: true,
					closeModal: true
				}
			},
			content: { element: content }, 
			closeOnClickOutside:false
		});
	}

	return true;
}

/**
 * Will return whether or not a field should be enabled or disabled, this will be a result of the combination of the facilities status
 * as well as the current role of the user
 */
export function isFieldEnabled(allowedRoles,allowedStates, facilityStatusId, session) {
	
	const currentRole = (session.Role === UserRoles.Admin ? UserRoles.Admin : session.AuthState.UserAppStructure.GroupAdminLevel ? UserRoles.GroupAdmin : UserRoles.User);
	if (facilityStatusId === 0) return true;

	var roleAllowed = ((allowedRoles.length === 0) || (allowedRoles.findIndex(function (v) { return v === currentRole }) !== -1));
	var statusAllowed = allowedStates.findIndex(function (v) { return v === facilityStatusId }) !== -1;
	if( currentRole === "2" && allowedRoles.includes(UserRoles.UserWithControl))
	{
		var checkPermission = hasControl("Facility Override",session);
		roleAllowed = checkPermission;
	
	}
	return roleAllowed && statusAllowed;
}