import { Roles } from "./Permissions";

export const Controls = {
	FacilityOverride: "Facility Override",
	SubmitPayableTransaction: "Submit Payable Transaction",
	SubmitDebtTransaction: "Submit Debt Transaction",
	SubmitPaymentInstruction: "Submit Payment Instruction",
	ResendEmailToAlternativeUser: "Re-send Email to Alternative User",
	ForceClosure: "Force Closure",
	TransactionOverride: "Transaction Override",
	UnlockRecovery: "Unlock Recovery",
	PaymentInstructionDetailsOverride:"Payment Instruction Details Override",
	Areas: {
		CreditManagement: "Credit Management"
	},
	SubAreas: {
		Facilities: "Facilities",
		TradeFinance: "Trade Finance",
		DebtorFinance: "Debtor Finance",
		PaymentManagement: "Payment Management",
	},
	Sections: {
		FacilityOverride: "Facility Override",
		SubmitPayableTransaction: "Submit Payable Transaction",
		ResendEmailToAlternativeUser: "Re-send Email to Alternative User",
		SubmitDebtTransaction: "Submit Debt Transaction",
		SubmitPaymentInstruction: "Submit Payment Instruction",
		ForceClosure: "Force Closure",
		UnlockRecovery: "Unlock Recovery",
		PaymentInstructionDetailsOverride:"Payment Instruction Details Override",
		TransactionStatusOverride: "Transaction Status Override"
	}
}

/**
 * @param { string } area
 * @param { string } subArea
 * @param { string } section
 * @param { import("../Services/UsersService").Session } session
 */
 export function hasControl(area, subArea, section, session) {

	if(session.Role === Roles.Admin||session.AuthState.UserAppStructure.GroupAdminLevel) {
		return true;
	} else {
		var control = session.AuthState.Controls.find(c => c.Section === section && c.Area === area && c.SubArea === subArea);

		if(control)
			return true;
	}

	return false;
}