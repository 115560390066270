import {View, Text} from '@react-pdf/renderer';
import { styles } from '../../StyleSheet';
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import Moment from 'moment';

export default function PaymentInstructionDetail(props){
    return(
        <View style={{paddingBottom: 20}}>
                    <View style={{paddingLeft: 5, paddingRight: 5}}>
                        <View style={styles.tableRowWrapping}>
                            <Text style={styles.paymentInstructionDetail}>Facility: <Text style={styles.boldFont}>{props.data.Facility.Name}</Text></Text>
                            <Text style={styles.paymentInstructionDetail}>Finance %: <Text style={styles.boldFont}>{(props.data.Facility.FinancialPercentage.toFixed(2))} %</Text></Text>
                            <Text style={styles.paymentInstructionDetail}>Facility Limit: <Text style={styles.boldFont}>{formatCurrencyValue(props.data.Facility.CurrencySymbol,props.data.FinanceDetails.FacilityBalances.Limit)}</Text></Text>
                            <Text style={styles.paymentInstructionDetail}>Facility Balance: <Text style={styles.boldFont}>{formatCurrencyValue(props.data.Facility.CurrencySymbol,props.data.FinanceDetails.FacilityBalances.Balances.ActualTotal)}</Text></Text>
                        </View>
                        <View style={styles.tableRowWrapping}>
                            <Text style={styles.paymentInstructionDetail}>Number: <Text style={styles.boldFont}>{props.data.Facility.FacilityNumber}</Text></Text>
                            <Text style={styles.paymentInstructionDetail}>Payment Date: <Text style={styles.boldFont}>{props.data.FinanceDetails.PaymentInstructionBalance.FacilityBatchDateID ? Moment(props.data.FinanceDetails.PaymentInstructionBalance.FacilityBatchDate.Date).format("DD MMMM yyyy") : props.data.FinanceDetails.PaymentInstructionBalance.PaymentDate ? Moment(props.data.FinanceDetails.PaymentInstructionBalance.PaymentDate).format("DD MMMM yyyy") : '-' }</Text></Text>
                            <Text style={styles.paymentInstructionDetail}>Payment/I Limit: <Text style={styles.boldFont}>{props.data.FinanceDetails.PaymentInstructionBalance.Limit ? formatCurrencyValue(props.data.Facility.CurrencySymbol,props.data.FinanceDetails.PaymentInstructionBalance.Limit ):'N/A'}</Text></Text>
                            <Text style={styles.paymentInstructionDetail}>Payment/I Balance: <Text style={styles.boldFont}>{props.data.FinanceDetails.PaymentInstructionBalance.Balances.RunningTotal ? formatCurrencyValue(props.data.Facility.CurrencySymbol,props.data.FinanceDetails.PaymentInstructionBalance.Balances.RunningTotal ):'N/A'}</Text></Text>
                        </View>
                    </View>
                </View>
    )
}