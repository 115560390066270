import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GetRandomKey } from "../../../../../Utilities/React";
import InputField from "../../../../Core/Forms/InputField";
import Panel from "../../../../Core/Panel";
import TextValue from "../../../../Core/TextValue";

export default function FacilityAgreements(props) {
	const formik = useFormikContext();
	const view = props.view;
	
	

	return 	<Panel title={ props.title }>
				{
					(props.heading1 || props.heading2) &&
					<Row>
						<Col><small>{ props.heading1 }</small></Col>
						<Col><small>{ props.heading2 }</small></Col>
					</Row>
				}
				<FieldArray name={ props.name } validateOnChange={false}>
					{({ push }) => (
						<div>
							{
								
								<React.Fragment>
									{ props.agreements && props.agreements.map((agreement, index) => (
										<Row key={ agreement.Key } className="mb-50">
											<Col><InputField  disabled={view} invalid={ (index === 0 && formik.errors[props.name]) } required={ index === 0 } name={ `${props.name}.${index}.Field` }></InputField></Col>
											<Col><InputField disabled={view}  invalid={ (index === 0 && formik.errors[props.name]) } required={ index === 0 } name={ `${props.name}.${index}.Value` }></InputField></Col>
										</Row>))
									}
									{!view && <Row>
										<Col className="d-flex justify-content-end">
											<Button onClick={() => push({ Field: "", Value: "", Key: GetRandomKey() })}>
												<FontAwesomeIcon transform="grow-2" icon={ faPlus } ></FontAwesomeIcon>
											</Button>
										</Col>
									</Row>
									}
								</React.Fragment>
							}
							{/* {
								view && props.agreements && props.agreements.map((agreement, index) => (
								<Row key={ agreement.Key } className="mb-50">
									<Col><TextValue value={agreement.Field}></TextValue></Col>
									<Col><TextValue value={agreement.Value}></TextValue></Col>
								</Row>))

							} */}

						</div>
					)}
				</FieldArray>
			</Panel>
}