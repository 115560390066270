import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import Moment from 'moment';
import React from "react";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { getStatusText } from "../../../../Utilities/PaymentInstructions";

export default function PaymentRecoveryPaymentRecoveries(props){

    return(
        <View style={{paddingBottom:10}}>
            <View style={styles.table}>
                <Text style={styles.textUnderline}>Payment Recoveries</Text>

                <View style={styles.tableRowWrapping}>
                    <Text style={[styles.textHeaders, {textAlign: 'left', width: '9%'}]}>Maturity Date</Text>
                    <Text style={[styles.textHeaders, {textAlign: 'left', width: '14%'}]}>Payment Instruction No.</Text>
                    <Text style={[styles.textHeaders, {textAlign: 'right', width: '11%'}]}>Document Total</Text>
                    <Text style={[styles.textHeaders, {textAlign: 'right', width: '11%'}]}>Payment Amount</Text>
                    <Text style={[styles.textHeaders, {textAlign: 'right', width: '11%'}]}>Interest</Text>
                    <Text style={[styles.textHeaders, {textAlign: 'right', width: '12%', paddingLeft:2, paddingRight:10}]}>Funding Amount</Text>
                    <Text style={[styles.textHeaders, {textAlign: 'left', width: '12%'}]}>Status</Text>
                    <Text style={[styles.textHeaders, {textAlign: 'left', width: '20%'}]}>Recovery Details</Text>
                </View>
                {
                    <View>
                        <View style={styles.tableRowWrapping}>
                            <React.Fragment>
                                <Text style={{textAlign: 'left', width: '9%', paddingTop:3, paddingBottom:3, fontSize:7}}>{Moment(props.data.MaturityDate).format("DD/MM/YYYY")}</Text>
                                <Text style={{textAlign: 'left', width: '14%', paddingTop:3, paddingBottom:3, fontSize:7, overflow:'hidden', textOverflow:'ellipsis', maxLines:1}}>{props.data.PaymentInstructionNumber}</Text>
                                <Text style={{textAlign: 'right', width: '11%', paddingTop:3, paddingBottom:3, fontSize:7}}>{formatCurrencyValue(props.data.Currency,props.data.Amount)}</Text>
                                <Text style={{textAlign: 'right', width: '11%', paddingTop:3, paddingBottom:3, fontSize:7}}>{formatCurrencyValue(props.data.Currency,props.data.PaymentAmount)}</Text>
                                <Text style={{textAlign: 'right', width: '11%', paddingTop:3, paddingBottom:3, fontSize:7}}>{formatCurrencyValue(props.data.Currency,(props.data.ProjectedInterest))}</Text>
                                <Text style={{textAlign: 'right', width: '12%', paddingLeft:2, paddingRight:10, paddingTop:3, paddingBottom:3, fontSize:7}}>{formatCurrencyValue(props.data.Currency,props.data.FundingTotal)}</Text>
                                <Text style={{textAlign: 'left', width: '12%', paddingTop:3, paddingBottom:3, fontSize:7}}>{getStatusText(props.data)}</Text>
                            </React.Fragment>
                        </View>
                        {
                            props.data.AllocatedPayments.map( value =>
                                <View>
                                    <React.Fragment>
                                        <View style={[styles.tableRowWrapping, {marginTop:-15, paddingBottom:15}]}>
                                            <Text style={{width: '80%', paddingTop:3, paddingBottom:3, fontSize:7}}></Text>
                                            <Text style={{textAlign: 'left', width: '10%', paddingTop:3, paddingBottom:3, fontSize:7}}>{ Moment( value.Timestamp ).format("DD/MM/YYYY")}</Text>
                                            <Text style={{textAlign: 'right', width: '10%', paddingTop:3, paddingBottom:3, fontSize:7}}>{formatCurrencyValue(props.data.Currency,value.Amount)}</Text>
                                        </View>
                                    </React.Fragment>
                                </View>
                            )
                        }
                         <View>
                            <React.Fragment>
                                <View style={[styles.tableRowWrapping, {marginTop:-15, paddingBottom:15}]}>
                                    <Text style={{width: '80%', paddingTop:3, paddingBottom:3, fontSize:7}}></Text>
                                    <Text style={{textAlign: 'left', width: '10%', paddingTop:3, paddingBottom:3, fontSize:7, fontWeight:700}}>Actual Received</Text>
                                    <Text style={{textAlign: 'right', width: '10%', paddingTop:3, paddingBottom:3, fontSize:7}}>{formatCurrencyValue(props.data.Currency,(props.data.TotalAllocated - props.data.TotalAdjusted))}</Text>
                                </View>
                                <View style={[styles.tableRowWrapping, {marginTop:-15, paddingBottom:15}]}>
                                    <Text style={{width: '80%', paddingTop:3, paddingBottom:3, fontSize:7}}></Text>
                                    <Text style={{textAlign: 'left', width: '10%', paddingTop:3, paddingBottom:3, fontSize:7, fontWeight:700}}>{ props.data.RemainingAmount < 0 ? "Unallocated" : "Outstanding"}</Text>{/* Outstanding/Unallocated */}
                                    <Text style={{textAlign: 'right', width: '10%', paddingTop:3, paddingBottom:3, fontSize:7, color: props.data.IsOverRecovered ? "red" : props.data.IsFullyRecovered ? "green" : "black" }}>{formatCurrencyValue(props.data.Currency,props.data.RemainingAmount)}</Text>
                                </View>
                            </React.Fragment>
                        </View>

                    </View>
                }
            </View>
        </View>
    )
}