import { View, Text } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { styles } from "../../StyleSheet";
import { getStatusText } from "../../../../Utilities/PaymentInstructions";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import Moment from 'moment';

export default function LinkedPaymentInstructions(props){
    return(
		<View style={{paddingBottom:10}}>
			<Text style={[styles.boldFont, {fontSize:7, padding: 3}]}>Linked Payment Instructions</Text>
			<View style={styles.tableRowWrapping} fixed>
				<Text style={[styles.facilityHeaders, {width: '15%', textAlign: 'left', borderRight:1, borderLeft:1, borderColor:'#e3e3e3'}]}>Number</Text>
				<Text style={[styles.facilityHeaders, {width: '19%', textAlign: 'left', borderRight:1, borderColor:'#e3e3e3'}]}>Name</Text>
				<Text style={[styles.facilityHeaders, {width: '10%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Payment Date</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Limit</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Running Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Actual Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Status</Text>
            </View>
			{
				props.data.PaymentInstructions.map(pi =>
				<View style={styles.tableRowWrapping} wrap={false}>
					<Text style={[styles.facilityData, {width: '15%', textAlign: 'left', borderRight:1, borderLeft:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ pi.Number }</Text>
					<Text style={[styles.facilityData, {width: '19%', textAlign: 'left', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ pi.Name }</Text>
					<Text style={[styles.facilityData, {width: '10%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ pi.PaymentDate ? Moment( pi.PaymentDate ).format("DD/MM/YYYY") : "N/A" }</Text>
					<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, pi.Limit)}</Text>
					<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, pi.Balances.RunningTotal)}</Text>
					<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, pi.Balances.ActualTotal)}</Text>
					<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1,  borderColor:'#e3e3e3'}]}>{ getStatusText(pi) }</Text>
				</View>
				)
			}

			<View style={styles.tableRowWrapping} wrap={false}>
				<Text style={[styles.facilityData, {width: '58%', textAlign: 'left', borderLeft:1, borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>Unlinked Transactions</Text>
				<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, props.data.UnlinkedTransactionBalances.RunningTotal)}</Text>
				<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, props.data.UnlinkedTransactionBalances.ActualTotal)}</Text>
				<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}></Text>
			</View>
		</View>
    )
}