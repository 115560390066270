import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FieldArray } from "formik"
import { DateTime } from "luxon"
import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import { useSessionStore } from "../../../../../../Stores/SessionStore"
import { DateFormats } from "../../../../../../Utilities/Enums"
import { getPermissions, Permissions } from "../../../../../../Utilities/Permissions"
import { GetRandomKey, removeModalTabIndex } from "../../../../../../Utilities/React"
import DateValue from "../../../../../Core/DateValue"
import CheckboxField from "../../../../../Core/Forms/CheckboxField"
import DateInput from "../../../../../Core/Forms/DateInput"
import NumberInput from "../../../../../Core/Forms/NumberInput"
import RoundButton from "../../../../../Core/RoundButton"
import NumberValue from "../../../../../Core/NumberValue"

export default function RateHistoryModal(props) {
	const [date, setDate] = useState("");
	const [rate, setRate] = useState("");

	const store = useSessionStore();

	useEffect(() => {
		if(props.show === false) {
			setDate("");
			setRate("");
		} else {
			removeModalTabIndex();
		}
	}, [props.show])

	function handleAddRateClicked(pushMethod) {
		if(date && (rate || rate === 0)) {
			pushMethod({ StartDate: date, Rate: rate, Active: false, RateHistoryType: props.rateType });
			setDate("");
			setRate("");
		}
	}

	function getSortedRates() {
		props.rates.sort((a, b) => {
            var aDate = DateTime.fromISO(a.StartDate);
            var bDate = DateTime.fromISO(b.StartDate);

            var result = bDate - aDate;

            return result;
        })

		return props.rates;
	}

	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	return <Modal show={props.show} size="lg">
				<Modal.Header>
					<h4>
						{props.title}
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						props.rates &&
						<FieldArray name={props.fieldName} validateOnChange={false}>
											{({ push, remove }) => (
						<React.Fragment>
							{
								permissions.Add &&
								<React.Fragment>
									<Row>
										<Col>
											<Form.Group as={Row} className="d-flex align-items-center mb-50">
												<Form.Label xs={4} column>Date</Form.Label>
												<Col>
													<DateInput date={date} onChange={ e => setDate(e) }></DateInput>
												</Col>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group as={Row} className="d-flex align-items-center mb-50">
												<Form.Label xs={4} column>Rate</Form.Label>
												<Col>
													<InputGroup>
														<NumberInput value={rate} onChange={ e => setRate(e) }></NumberInput>
														<InputGroup.Text>%</InputGroup.Text>
													</InputGroup>
												</Col>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex justify-content-end mb-2">
											<Button onClick={ e => handleAddRateClicked(push) }>Add</Button>
										</Col>
									</Row>
								</React.Fragment>
							}
							<Row>
								<Col>
									<Table bordered className="align-middle" size="sm" responsive>
										<thead>
											<tr>
												<th>Date</th>
												<th>Rate</th>
												<th>Status</th>
												<th className="text-center">Delete</th>
											</tr>
										</thead>
										<tbody>
											{ getSortedRates().map((history, index) => (
											<tr key={ GetRandomKey() }>
												<td><DateValue date={ history.StartDate } outputFormat={ DateFormats.FullLongDate }></DateValue> </td>
												<td><NumberValue value={ history.Rate }></NumberValue>%</td>
												<td>
													<CheckboxField col={1} swap label="Active" name={ `${props.fieldName}[${index}].Active` }></CheckboxField>
												</td>
												<td className="text-center">
													<RoundButton onClick={ e => remove(index) } icon={ faTimes }></RoundButton>
												</td>
											</tr>))}
										</tbody>
									</Table>
								</Col>
							</Row>
						</React.Fragment>
						)}
						</FieldArray>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ props.onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}