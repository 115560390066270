import React from "react";
import { styles } from "../../StyleSheet";
import { View, Text } from "@react-pdf/renderer";
 import { formatCurrencyValue } from "../../../../Utilities/Currencies";
 import TransactionCurrencyConverstion from '../PaymentInstruction/Transactions/TransactionCurrencyConversion';

export default function PaymentTotals(props){
    const showConversion = props.data.find((x) => x.Currency.Symbol !== props.currency);
    const showRecoveries = props.showRecoveries;

    return(
        <View style={{paddingBottom: 10}}>
            <View style={styles.table}>
                <View style={styles.tableRowWrapping}>
                <Text style={[styles.textHeaders, {textAlign:'left', width:(showRecoveries? '19%':'30%')}]}>{showRecoveries ? "Payment Recovery Totals":"Payment Instruction Totals"}</Text>
                   
                    <Text style={[styles.textHeaders, {textAlign:'center', width:(showRecoveries? '6%':'6%')}]}>Currency</Text>
                    <Text style={[styles.textHeaders, {textAlign:'right', width:(showRecoveries? '14%':'18%')}]}>Document Amount</Text>
                    <Text style={[styles.textHeaders, {textAlign:'right', width:(showRecoveries? '10%':'10%')}]}>VAT</Text>
                    <Text style={[styles.textHeaders, {textAlign:'right', width:(showRecoveries? '14%':'18%')}]}>Document Total</Text>
                    <Text style={[styles.textHeaders, {textAlign:'right', width:(showRecoveries? '14%':'18%')}]}>Payment Amount</Text>
                    {showRecoveries &&
                        <React.Fragment>
                            <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Interest</Text>
                            <Text style={[styles.textHeaders, {textAlign:'right', width:'13%'}]}>Funding Amount</Text>
                        </React.Fragment>
                    }
                    
                </View>
              { props.data.map(value =>
                <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters, {textAlign:'left', width:(showRecoveries? '19%':'30%')}]}></Text>
                    <Text style={[styles.textFooters, {textAlign:'center', width:(showRecoveries? '6%':'6%')}]}>{value.Currency.Abbreviation}</Text>
                    <Text style={[styles.textFooters, {textAlign:'right', width:(showRecoveries? '14%':'18%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.DocumentAmountTotal)}</Text>
                    <Text style={[styles.textFooters, {textAlign:'right', width:(showRecoveries? '10%':'10%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.VatTotal)}</Text>
                    <Text style={[styles.textFooters, {textAlign:'right', width:(showRecoveries? '14%':'18%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.AmountTotal)}</Text>
                    <Text style={[styles.textFooters, {textAlign:'right', width:(showRecoveries? '14%':'18%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.PaymentAmountTotal)}</Text>
                    {showRecoveries &&
                        <React.Fragment>
                            <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.Currency.Symbol,value.IntrestAmountTotal)}</Text>
                            <Text style={[styles.textFooters, {textAlign:'right', width:'13%'}]}>{formatCurrencyValue(value.Currency.Symbol,value.FundingAmountTotal)}</Text>
                        </React.Fragment>
                    }
                </View>)}
                {showConversion &&
                    <TransactionCurrencyConverstion data={props.convertedTotals} facilityType ={props.facilityType}  showRecoveries={props.showRecoveries} currency={props.currency}/>
                }
            </View>
        </View>
    )
}