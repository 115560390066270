import {View, Text} from '@react-pdf/renderer';
import { styles } from '../../StyleSheet';
import { formatCurrencyValue } from '../../../../Utilities/Currencies';
import Moment from 'moment';

export default function MaturityDateTransactions(props){
    return(
        <View style={styles.table}>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textHeaders, {textAlign: 'Left', width: '20%'}]}>Maturity Date</Text>
                <Text style={[styles.textHeaders, {textAlign: 'Left', width: '20%'}]}>Payment Instruction No.</Text>
                <Text style={[styles.textHeaders, {textAlign: 'right', width: '20%'}]}>Document Total</Text>
                <Text style={[styles.textHeaders, {textAlign: 'right', width: '20%'}]}>Interest</Text>
                <Text style={[styles.textHeaders, {textAlign: 'right', width: '20%'}]}>Funding Amount</Text>
            </View>
            {props.data.Result.map(c => 
            <View  style={styles.tableRowWrapping}>    
                <Text style={[styles.textData, {textAlign: 'Left', width: '20%'}]}>{Moment(c.MaturityDate).format("DD/MM/YYYY")}</Text>
                <Text style={[styles.textData, {textAlign: 'Left', width: '20%'}]}>{c.PINumber}</Text>
                <Text style={[styles.textData, {textAlign: 'right', width: '20%'}]}>{formatCurrencyValue(c.CurrencyID,c.TotAmount)}</Text>
                <Text style={[styles.textData, {textAlign: 'right', width: '20%'}]}>{formatCurrencyValue(c.CurrencyID,c.TotIntrest)}</Text>
                <Text style={[styles.textData, {textAlign: 'right', width: '20%'}]}>{formatCurrencyValue(c.CurrencyID,c.Totfunding)}</Text>  
            </View>)
            }
        </View>
    )
}