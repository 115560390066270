import { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import { useSessionStore } from "../Stores/SessionStore";
import { observer } from "mobx-react-lite";
import Main from "./Pages/Main";
import Login from "./Pages/Login";
import BusyIndicator from "./Core/BusyIndicator";
import ScrollToTop from "./Core/ScrollToTop";

const App = observer(function App() {
	const store = useSessionStore();

	useEffect(() => {
		//initialise the app
		
		
		if(store.Session) {
			store.LoadAuthState();
		}

	}, [store.Session]);

	

	return 	<HashRouter>
				<ScrollToTop>
					{
						store.Session && store.Session.AuthState &&
						<Main></Main>
					}
					{
						store.Session === null &&
						<Login></Login>
					}
					{
						(store.Session && !store.Session.AuthState) &&
						<div className="d-flex w-100 h-100 justify-content-center align-items-center">
							<BusyIndicator size="3x" show></BusyIndicator>
						</div>
					}
				</ScrollToTop>
			</HashRouter>
});

export default App;