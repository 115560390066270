import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import Crestlogo from '../../../../Images/crest management system-LOGO.png';
import moment from "moment";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function FacilityMovementHeader(props){

 

    return(
        <View>
            <View style={{display: 'flex', flexDirection: 'row', justifyContent:'center', paddingLeft: 4, paddingRight: 4, paddingBottom: 15}}>
                <View style={{width:'20%', padding:1}}>
                    {
                        !props.data.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
                    }
                    {
                        props.data.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start", paddingLeft:1}} src={ `data:${props.data.AssignmentPictureMIMEType};base64,${props.data.AssignmentPictureBase64}` } />
                    }
                </View>
                <View style={{width:'60%', padding:1}}>
                    <Text style={[styles.Title, {textAlign:'center', paddingTop:10}]}>Facility Movement Report - {props.detailed ? "Detailed" : "Summary"}</Text>
                </View>
                <View style={{width:'20%', padding:1}}>
                    {
                        !props.data.ProfilePictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-end"}} src={ Crestlogo } />
                    }
                    {
                        props.data.ProfilePictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-end"}} src={ `data:${props.data.ProfilePictureMIMEType};base64,${props.data.ProfilePictureBase64}` } />
                    }
                </View>
            </View>

            <View style={[styles.tableRowWrapping, {fontSize:7, padding:10, marginBottom:-15}]}>
                <Text style={{textAlign:'left', width:'25%'}}>Group: <Text style={styles.boldFont}>{ props.data.GroupName }</Text> </Text>
                <Text style={{textAlign:'left', width:'25%'}}></Text>
                <Text style={{textAlign:'left', width:'25%'}}>From Date: <Text style={styles.boldFont}>{ moment(props.data.FromDate).format("D MMMM YYYY") }</Text></Text>
                <Text style={{textAlign:'left', width:'25%'}}>To Date: <Text style={styles.boldFont}>{ moment(props.data.ToDate).format("D MMMM YYYY") }</Text></Text>
            </View>
            <View style={[styles.tableRowWrapping, {fontSize:7, padding:10}]}>
                <Text style={{textAlign:'left', width:'25%'}}>Facility: <Text style={styles.boldFont}>{ props.data.Facilties.Name }</Text></Text>
                <Text style={{textAlign:'left', width:'25%'}}>Facility Limit: <Text style={styles.boldFont}>{ props.data.Facilties.Limit }</Text></Text>
                <Text style={{textAlign:'left', width:'25%'}}>Advance Date: <Text style={styles.boldFont}>{ props.data.Facilties.AdvanceDate }</Text></Text>
                <Text style={{textAlign:'left', width:'25%'}}>To Termination: <Text style={styles.boldFont}>{ props.data.Facilties.TerminationDate }</Text></Text>
            </View>
            {
                props.data.Facilties.map(values => 
                    <View style={[styles.tableRowWrapping, {fontSize:7, paddingLeft:10, marginTop:-18, paddingBottom:18}]}>
                        <Text style={{textAlign:'left', width:'25%', marginLeft:25}}><Text style={styles.boldFont}>{ values.Name }</Text></Text>
                        <Text style={{textAlign:'left', width:'25%', marginLeft:60}}><Text style={styles.boldFont}>{ formatCurrencyValue(values.Currency, values.Limit) }</Text></Text>
                        <Text style={{textAlign:'left', width:'25%', marginLeft:45}}><Text style={styles.boldFont}>{ moment(values.AdvanceDate).format("D MMMM YYYY") }</Text></Text>
                        <Text style={{textAlign:'left', width:'25%', marginLeft:45}}><Text style={styles.boldFont}>{ moment(values.TerminationDate).format("D MMMM YYYY") }</Text></Text>
                    </View>
                )
            }
        </View>
    )
}