import { View,Text } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import moment from "moment";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function FacilityMovementClosingBalnce(props){
    return(
        <View style={[styles.tableRowWrapping, {paddingTop:3, paddingBottom:3}]}>
            <Text style={[styles.textFooters,{textAlign:'left', width:'24%'}]}>Closing Balances as at {moment(props.footer.ToDate).format("D MMMM YYYY")}</Text>
            <Text style={[styles.textFooters,{textAlign:'left', width:'18%'}]}></Text>
            <Text style={[styles.textFooters,{textAlign:'left', width:'6%'}]}></Text>
            <Text style={[styles.textFooters,{textAlign:'left', width:'16%'}]}></Text>
            <Text style={[styles.textFooters,{textAlign:'right', width:'12%', marginLeft:(props.detailed ? 0 : 5)}]}>{formatCurrencyValue(props.currency, props.footer.ClosingDebit)}</Text>
            <Text style={[styles.textFooters,{textAlign:'right', width:'12%'}]}>{formatCurrencyValue(props.currency, props.footer.ClosingCredit)}</Text>
            <Text style={[styles.textFooters,{textAlign:'right', width:'12%'}]}>{formatCurrencyValue(props.currency, props.footer.ClosingBalance)}</Text>
        </View>
    )
}