import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import NumberInput from "../../../../../Core/Forms/NumberInput";
import NumberValue from "../../../../../Core/NumberValue";

export default function FacilityFunderLimitField(props) {
	return 	<Form.Group as={Row}  className={ `align-items-center ${props.className ? props.className : ""}` }>
				{
					props.label &&
					<Form.Label column>Limit</Form.Label>
				}
				
					{
						!props.view &&
						<Col xs={ props.col ? props.col : 8}>
							<InputGroup onClick={ props.onClick} >
								<InputGroup.Text>{ props.currency }</InputGroup.Text>
								<NumberInput style={{ cursor: "not-allowed" }} disabled value={props.limit}></NumberInput>
								<Button><FontAwesomeIcon transform="grow-2" icon={ faPlus }></FontAwesomeIcon></Button>
							</InputGroup>
						</Col>
					}
					{
						props.view &&
						<Col xs={ props.col ? props.col : 8} style={{ color: "#004878", cursor: "not-allowed" }}>
							<NumberValue currency={props.currency} value={ props.limit }></NumberValue>
						</Col>
					}
			</Form.Group>
}