export var FacilityTypes = {
	TradeFinance: 1,
	InvoiceDiscounting: 2
}

export var FacilityConditions = {
	InterestAccruingOnDrawnPortionsOnly: 1,
	InterestAccruingOnFacilityLimit: 2
}

export var DateFormats = {
	FullLongDate: "dd MMM yyyy"
}

export var DateInputFormats = {
	FullLongDate: "d F Y"
}

export var RateHistoryTypes = {
	BaseInterestRate: 0,
	CostOfFinanceFundersMargin: 1,
	CostOfFinanceTradingMargin: 2,
	UnderutilisationFeeFunderMargin: 3,
	UnderutilisationFeeTradingMargin: 4
}

export var PaymentInstructionStatuses = {
	Open: 1,
	Pending: 2,
	AwaitingConfirmation: 3,
	Confirmed: 4,
	Declined: 5,
	AwaitingAuthorisation: 6,
	Authorised: 7,
	NTU: 8,
	Processed: 9
}

export var TransactionStatuses = {
	Open: 1,
	Pending: 2,
	AwaitingApproval: 3,
	Approved: 4,
	Declined: 5,
	AwaitingVerification: 6,
	Verified: 7,
	NTU: 8,
	Processed: 9,
	PaidOut: 10,
	Recovered: 11
}

export var FacilityStatuses = {
	Pending: 1,
    Active: 2,
    Cancelled: 3,
    Expired: 4
}

export var UserRoles = {
	Admin: "1",
    User: "2",
	GroupAdmin: "3",
	UserWithControl: "4"
}

export var TransactionDocumentTypes = {
	Invoice: 0, 
	CreditNote : 1
}

export var SettingTypes = {
	TextBox: 0,
	HtmlBox: 1,
	MultipleUserSelectList: 2,
	Custom: 3
}