import { faAddressBook, faAddressCard, faBellExclamation, faBoxCircleCheck, faBriefcase, faBuildingColumns, faCartShopping, faChartLine, faCircleDollar, faClipboardListCheck, faCreditCard, faFileChartColumn, faGear, faHandshake, faPeopleGroup, faRightFromBracket, faScaleBalanced, faShip, faSliders, faUsers, faWallet, faWrench } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "../../Vuexy/js/components/menu/Menu";
import swal from "sweetalert"
import { useSessionStore } from "../../Stores/SessionStore";
import { Roles,hasSubPermissions,hasMenuPermissions, Permissions ,PermissionAction,hasSectionPermissions,hasSectionPermissionsbyArea} from "../../Utilities/Permissions";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import ReportModal from "../Reports/ReportModal";


const Sidebar = observer(function Sidebar() {
	const store = useSessionStore();
	const [canSeeMe,setcanSeeMe] = useState(true);
	
	function notAllowed() {
		swal({icon: "warning", text: "You do not have access to this section. Please contact the system administrator.", title: "Access Denied", closeOnClickOutside: false});
	}

	function getNotificationsIcon(hideWhenNoMessages) {
		if(store.HasNotifications === false) {
			if(hideWhenNoMessages) {
				return null;
			} else {
				return <FontAwesomeIcon icon={ faBellExclamation }></FontAwesomeIcon>
			}
		} else {
			return <FontAwesomeIcon className="text-danger" icon={ faCircleExclamation }></FontAwesomeIcon>
		}
	}
	useEffect(() => {
	  
		checkProfileandPermissions();
	});
	
async function checkProfileandPermissions()
{
	var result =  await store.UsersService.GetProfile();
 
		if(result && result.Success)
		{
     
			
			if(result.Data.ShowAllMenu)
			setcanSeeMe(result.Data.ShowAllMenu);
			else{
				setcanSeeMe(false);

			}
			
		}
		
}
	function handleLogoutClicked() {
		window.location.replace("/");
		store.Logout()
	}
	
	const menuItems = [
		{ id: 'UserMenu', title: (store.Session ? store.Session.FullName : "" ), Profile: true, icon: getNotificationsIcon(true),
			children: [	{ id: 'Notifications', title: 'Notifications', icon: getNotificationsIcon(), navLink: 'Account/Notifications' },
						{ id: 'Profile', title: 'Profile', navLink: 'Account/Profile', icon: <FontAwesomeIcon icon={ faAddressCard }></FontAwesomeIcon>},
						{ id: 'Logout', title: 'Logout', icon: <FontAwesomeIcon icon={ faRightFromBracket }></FontAwesomeIcon>,  onClick: () => handleLogoutClicked() }] }]

if((store.Session.Role === Roles.Admin||store.Session.AuthState.UserAppStructure.GroupAdminLevel) || canSeeMe || hasSubPermissions(Permissions.Areas.MyZone,"",store.Session)  ){menuItems.push({ id: 'MyZone', title: 'My Zone', icon: <FontAwesomeIcon icon={ faChartLine }></FontAwesomeIcon> ,
			children: []	})}
		let myzoneChilderen =[{ id: 'Home', title: 'Home', navLink: 'MyZone/Home' } ,
			{ id: 'Finance', title: 'Finance', onClick: notAllowed},{ id: 'Credit', title: 'Credit', onClick: notAllowed},{ id: 'Trade', title: 'Trade', onClick: notAllowed}]
			myzoneChilderen.forEach(element => {
				if(hasSectionPermissionsbyArea(Permissions.Areas.MyZone,element.title,store.Session))
					{
						menuItems.find(x => x.id === 'MyZone').children.push(element)
				 
					}
				});

if((store.Session.Role === Roles.Admin||store.Session.AuthState.UserAppStructure.GroupAdminLevel) || canSeeMe  ){menuItems.push({ id: 'FinancialManagement', title: 'Financial Management', icon: <FontAwesomeIcon icon={ faCircleDollar }></FontAwesomeIcon>, onClick: notAllowed },)}
                 
						if((store.Session.Role === Roles.Admin||store.Session.AuthState.UserAppStructure.GroupAdminLevel)|| canSeeMe || hasMenuPermissions(Permissions.Areas.CreditManagement,store.Session)){menuItems.push({ id: 'CreditManagement', title: 'Credit Management', icon: <FontAwesomeIcon icon={ faCreditCard }></FontAwesomeIcon>,
							children: [],
						})}
						
							if(hasSubPermissions(Permissions.Areas.CreditManagement,Permissions.SubAreas.Facilities,store.Session))
							
							{menuItems.find(x => x.id === 'CreditManagement').children.push({ id: 'Facilities', title: 'Facilities', icon: <FontAwesomeIcon icon={ faBuildingColumns }></FontAwesomeIcon>,
							children: [],
							
						   })};
						   let Menuchildren = [	{ id: 'AllFacilities', title: 'All Facilities', navLink: 'Facilities'},
						   { id: 'Underutilisation', title: 'Underutilisation', navLink: 'Underutilisation'},
						   { id: 'FacilityReview', title: 'Facilities Review', onClick: () => {}}]
		   					Menuchildren.forEach(element => {
			   					if(hasSectionPermissions(Permissions.SubAreas.Facilities,element.title,store.Session))
			   						{
				   						menuItems.find(x => x.id === 'CreditManagement').children.find(x => x.id = 'Facilities').children.push(element)
									
			   						}
			   
		   });

		  
		   if(hasSubPermissions(Permissions.Areas.CreditManagement,Permissions.SubAreas.TradeFinance,store.Session))
		   
		   {menuItems.find(x => x.id === 'CreditManagement').children.unshift({ id: 'TradeFinance', title: 'Trade Finance', icon: <FontAwesomeIcon size="3x" icon={ faShip }></FontAwesomeIcon>,
		   children: [],
		   
		   
		  })};
		  
		  
		  let Tradechildren = [	{ id: 'VendorDocuments', title: 'Vendor Documents', navLink: 'TradeFinance/VendorDocuments'},
		  						{ id: 'PayableTransactions', title: 'Payable Transactions', navLink: 'TradeFinance/PayableTransactions'},
		  						{ id: 'TransactionRecoveries', title: 'Transaction Recoveries', navLink: 'TradeFinance/TransactionRecoveries'}]
			  Tradechildren.forEach(element => {
				  if(hasSectionPermissions(Permissions.SubAreas.TradeFinance,element.title,store.Session,))
					  {
						  menuItems.find(x => x.id === 'CreditManagement').children.find(x => x.id === 'TradeFinance').children.push(element)
					  }

					});

					if(hasSubPermissions(Permissions.Areas.CreditManagement,Permissions.SubAreas.DebtorFinance,store.Session,PermissionAction.View))
		   
					{menuItems.find(x => x.id === 'CreditManagement').children.unshift({ id: 'DebtorFinance', title: 'Debtor Finance', icon: <FontAwesomeIcon icon={ faScaleBalanced }></FontAwesomeIcon>,
					children: [],
					
					
				   })};
				   
				   
				   let Debtchildren = [	{ id: 'CustomerDocuments', title: 'Customer Documents', navLink: 'DebtorFinance/CustomerDocuments'},
				   						{ id: 'DebtTransactions', title: 'Debt Transactions', navLink: 'DebtorFinance/DebtTransactions'},
				   						{ id: 'TransactionRecoveries', title: 'Transaction Recoveries', navLink: 'DebtorFinance/TransactionRecoveries'}]
										   Debtchildren.forEach(element => {
						   if(hasSectionPermissions(Permissions.SubAreas.DebtorFinance,element.title,store.Session,PermissionAction.View))
							   {
								   menuItems.find(x => x.id === 'CreditManagement').children.find(x => x.id === 'DebtorFinance').children.push(element)
							   }
		 
							 });
						   
							
							 if(hasSubPermissions(Permissions.Areas.CreditManagement,Permissions.SubAreas.PaymentManagement,store.Session,PermissionAction.View))
		   
							 {menuItems.find(x => x.id === 'CreditManagement').children.unshift({ id: 'PaymentManagement', title: 'Payment Management', icon: <FontAwesomeIcon icon={ faWallet }></FontAwesomeIcon>,
							 children: [],
							 
							 
							})};
							
							
							let paychildren = [	{ id: 'PaymentInstructions', title: 'Payment Instructions',look: 'Payment Instructions' , navLink: '/PaymentManagement/PaymentInstructions'},
													{ id: 'PaymentProcessing', title: 'Payment Processing',look:'Payment Processing', navLink: '/PaymentManagement/PaymentProcessing'},
													{ id: 'PaymentRecoveries', title: 'Payment Recoveries',look:'Payment Recoveries', navLink: '/PaymentManagement/PaymentRecoveries'},
													{ id: 'Reimbursements', title: 'Reimbursements',look:'Payment Reimbursements', navLink: '/PaymentManagement/Reimbursements'}]
													paychildren.forEach(element => {
									if(hasSectionPermissions(Permissions.SubAreas.PaymentManagement,element.look,store.Session,PermissionAction.View))
										{
											menuItems.find(x => x.id === 'CreditManagement').children.find(x => x.id === 'PaymentManagement').children.push(element)
										}
				  
									  });
									  
											   if(hasSubPermissions(Permissions.Areas.CreditManagement,Permissions.SubAreas.Reports,store.Session,PermissionAction.View))
							 
											   {menuItems.find(x => x.id === 'CreditManagement').children.unshift({ id: 'Reports', title: 'Reports', navLink:'/Reports/CreditManagementReports' , icon: <FontAwesomeIcon icon={ faFileChartColumn }></FontAwesomeIcon>,
											   
											   
											   
											  })};
											 
											  
										
													  
							
								
						// 
						// 			children: 

						// 						hasSubPermissions(Permissions.Areas.CreditManagement,Permissions.SubAreas.DebtorFinance,store.Session,PermissionAction.View)?
						// 			children: [	]}  : <div></div>,

						// 
						// 			children: [	]},

						// 
						// 			children: [
						// 				
						// 			]
						// 			/*onClick: () => {}*/
						// ; 
					
						{menuItems.find(x => x.id === 'CreditManagement').children.reverse()}
		if((store.Session.Role === Roles.Admin||store.Session.AuthState.UserAppStructure.GroupAdminLevel)|| canSeeMe){menuItems.push({ id: 'TradeManagement', title: 'Trade Management', icon: <FontAwesomeIcon icon={ faBriefcase }></FontAwesomeIcon>,
			children: [	{ id: 'TradeControl', title: 'Trade Control', icon: <FontAwesomeIcon icon={ faBoxCircleCheck }></FontAwesomeIcon>, onClick: notAllowed },

						{ id: 'Procurement', title: 'Procurement', icon: <FontAwesomeIcon icon={ faCartShopping }></FontAwesomeIcon>,
									children: [	{ id: 'PurchaseDocuments', title: 'Purchase Documents', onClick: notAllowed} ,
												{ id: 'PurchaseOrders', title: 'Purchase Orders', onClick: notAllowed },
												{ id: 'PurchaseReceipts', title: 'Purchase Receipts', onClick: notAllowed }]},

						{ id: 'Sales', title: 'Sales', icon: <FontAwesomeIcon icon={ faHandshake }></FontAwesomeIcon>,
									children: [	{ id: 'SalesDocuments', title: 'Sales Documents', onClick: notAllowed} ,
												{ id: 'SalesOrders', title: 'Sales Orders', onClick: notAllowed},
												{ id: 'Returns', title: 'Returns', onClick: notAllowed}]},
						{ id: 'ItemAdjustments', title: 'Item Adjustments', icon: <FontAwesomeIcon icon={ faSliders }></FontAwesomeIcon>, onClick: notAllowed},

						{ id: 'Inventory', title: 'Inventory', icon: <FontAwesomeIcon icon={ faClipboardListCheck }></FontAwesomeIcon>,

									children: [	{ id: 'InventoryItems', title: 'Inventory Items', onClick: notAllowed} ,
												{ id: 'Warehouses', title: 'Warehouses', onClick: notAllowed},
												{ id: 'Categories', title: 'Categories', onClick: notAllowed},
												{ id: 'Departments', title: 'Departments', onClick: notAllowed},
												{ id: 'InventoryClasses', title: 'Inventory Classes', onClick: notAllowed}]}] })};
	

	if(store.Session.Role === Roles.Admin) {
		menuItems.push({ id: 'Admin', title: 'Admin', icon: <FontAwesomeIcon icon={ faWrench }></FontAwesomeIcon>,
						children: [	{ id: 'Users', title: 'Users', icon: <FontAwesomeIcon icon={ faUsers }></FontAwesomeIcon>, 
									children: [
										{ id: 'UserTypes', title: 'User Types', navLink: '/Admin/UserTypes'} ,
										{ id: 'UserIndex', title: 'Users', navLink: '/Admin/Users' }
									]},
									{ id: 'Groups', title: 'Groups', icon: <FontAwesomeIcon icon={ faPeopleGroup }></FontAwesomeIcon>, navLink: '/Admin/Groups'},
									{ id: 'Contacts', title: 'Contacts', icon: <FontAwesomeIcon icon={ faAddressBook }></FontAwesomeIcon>,
												children: [	{ id: 'ContactCategories', title: 'Contact Categories', navLink: '/Admin/ContactCategories'} ,
															{ id: 'ContactTypes', title: 'Contact Types', navLink: '/Admin/ContactTypes' },
															{ id: 'ContactCountries', title: 'Countries', navLink: '/Admin/Countries' },
															{ id: 'ContactsIndex', title: 'Contacts', navLink: '/Admin/Contacts' }]},

									{ id: 'Settings', title: 'Settings', icon: <FontAwesomeIcon icon={ faGear }></FontAwesomeIcon>,
												children: [	{ id: 'System', title: 'System', navLink: '/Admin/Settings/System'} ,
															{ id: 'Group', title: 'Group', navLink: '/Admin/Settings/Group'},
															{ id: 'MarketPosition', title: 'Market Position', navLink: '/Admin/Settings/MarketPosition'}]},						

									{ id: 'Reports', title: 'Reports', icon: <FontAwesomeIcon icon={ faFileChartColumn }></FontAwesomeIcon>, onClick: notAllowed}
								]});
	}

	if(store.HideSidebar === false) {
		return <Menu menuData={ menuItems }></Menu>
		
	}

	return null;
});

export default Sidebar;