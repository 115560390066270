import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../../Core/BusyIndicator";
import CopyFromUser from "./CopyFromUser";

export default function PermissionsTab(props) {
	const store = useSessionStore();
	const formikContext = useFormikContext();

	const [permissions, setPermissions] = useState(null);

	let area = "";
	let subArea = "";

	async function getPermissions() {
		let results = await store.SystemAppsService.GetPermissions();

		if(results && results.Success) {
			
		
			results.Data.map( x => {
				if(x.PermissionId === 33)
				{
					x.Name = "Reimbursements"
				}
				return x;
			})
			setPermissions(results.Data);
		}
	}

	function copyFromUser(data) {
		if(data) {
			formikContext.setValues({...formikContext.values, Permissions: data.Permissions });
		}
	}

	useEffect(() => {
		getPermissions();
	}, []);

	function getAreaHeader(permissionArea) {
		if (area !== permissionArea) {
			area = permissionArea;
			return <tr>
						<td colSpan="5">
							<strong className="text-info">{ area }</strong>
						</td>
					</tr>
		}

		return false;
	}

	function getSubAreaHeader(permissionSubArea) {
		if (subArea !== permissionSubArea) {
			subArea = permissionSubArea;
			if (subArea) {
				return 	<tr>
							<td colSpan="5">
								<strong>{ subArea }</strong>
							</td>
						</tr>
			}
		}

		return false;
	}

	function handlePermissionChecked(permission, action, checked) {
		let permissions = formikContext.values.Permissions;
		let setPermissions = permissions.filter(p => p.PermissionId === permission.PermissionId);

		if(setPermissions.length === 0) {
			if(checked) {
				let newPermission = { Id: 0, PermissionId: permission.PermissionId, Add: false, View: false, Edit: false, Delete: false };
				newPermission[action] = true;

				permissions.push(newPermission);
			}			
		} else {
			if(checked) {
				for(let i = 0; i < setPermissions.length; i++) {
					let setPermission = setPermissions[i];
					setPermission[action] = true;
				}
			} else {
				for(let i = 0; i < setPermissions.length; i++) {
					let setPermission = setPermissions[i];
					setPermission[action] = false;
				}
			}
		}
		
		formikContext.setValues({...formikContext.values, Permissions: permissions });
	}

	function isPermissionChecked(permission, action) {
		let permissions = formikContext.values.Permissions;
		let setPermission = permissions.find(p => p.PermissionId === permission.PermissionId && p[action]);

		if(setPermission && setPermission[action]) {
			return true;
		}

		return false;
	}

	return 	<div>
				<CopyFromUser copyFromUser={ copyFromUser } users={props.users} systemAppId={ props.selectedSystem ? props.selectedSystem.SystemApp.SystemId : "" }></CopyFromUser>
				<Row>
					<Col>
					{
						permissions &&
						<Table size="sm" responsive bordered>
							<tbody>
							<tr>
								<td className="py-50"><b>Section</b></td>								
								<td className="text-center col-1"><b>View</b></td>
								<td className="text-center col-1"><b>Add</b></td>
								<td className="text-center col-1"><b>Edit</b></td>
								<td className="text-center col-1"><b>Delete</b></td>
							</tr>
							{
								
								permissions.map((p, index) => 	
								<React.Fragment>
									{
										getAreaHeader(p.Area)
									}
									{
										getSubAreaHeader(p.SubArea)
									}
									<tr key={ p.PermissionId }>
										<td>{p.Name}</td>
										<td className="text-center">
											<input className="mt-50" type="checkbox" value="View" checked={ isPermissionChecked(p, "View") } onChange={ e => handlePermissionChecked(p, "View", e.target.checked) } />
										</td>
										<td className="text-center">
											<input className="mt-50" type="checkbox" value="Add" checked={ isPermissionChecked(p, "Add") } onChange={ e => handlePermissionChecked(p, "Add", e.target.checked) } />
										</td>
										<td className="text-center">
											<input className="mt-50" type="checkbox" value="Edit" checked={ isPermissionChecked(p, "Edit") } onChange={ e => handlePermissionChecked(p, "Edit", e.target.checked) } />
										</td>
										<td className="text-center">
											<input className="mt-50" type="checkbox" value="Delete" checked={ isPermissionChecked(p, "Delete") } onChange={ e => handlePermissionChecked(p, "Delete", e.target.checked) } />
										</td>
									</tr>
								</React.Fragment>)
							}
							</tbody>
						</Table>
					}
					</Col>
				</Row>
				<BusyIndicator show={ permissions === null }></BusyIndicator>
			</div>
}