import { useFormikContext } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../../Core/BusyIndicator";
import CheckboxField from "../../../../../Core/Forms/CheckboxField";
import ImageUploadInput from "../../../../../Core/Forms/ImageUploadInput";
import SelectField from "../../../../../Core/Forms/SelectField";
import Panel from "../../../../../Core/Panel";

export default function Preferences(props) {
	const store = useSessionStore();
	const formikContext = useFormikContext();

	const [currencies, setCurrencies] = useState(null);
	
	async function getCurrencies() {
		let result = await store.CurrenciesService.GetAvailableCurrencies("", 0, 0);

		if(result && result.Success) {
			setCurrencies(result.Data);
		}
	}

	useEffect(() => {
		getCurrencies();
	}, []);

	function handleFileChanged(file, base64String) {
		let values = formikContext.values;

		values.ProfilePictureBase64 = base64String;

		if(file) {
			values.ProfilePictureFileName = file.name;
			values.ProfilePictureMIMEType = file.type;
		}
		
		values.ProfilePicChanged = true;

		formikContext.setValues(values);
	}

	return 	<React.Fragment>
				<Panel title="User Preferences">
					{
						currencies &&
						<Row>
							<Col>
								<SelectField required view={props.view} name="PreferredLanguageId" label="Language">
									<option value="">No Preference</option>
									<option value="0">English (UK)</option>
									<option value="1">French</option>
									<option value="2">Portuguese</option>
									<option value="3">German</option>
								</SelectField>
							</Col>
							<Col>
								<SelectField required view={props.view} name="PreferredCurrencyId" label="Currency">
									<option value="">No Preference</option>
									{
										currencies.map((c) => 
											<option key={c.Id} value={c.Id}>{c.Name}</option>
										)
									}
								</SelectField>
							</Col>
						</Row>
					}
					<BusyIndicator show={ currencies === null }></BusyIndicator>
				</Panel>
				<Panel title="Extra Account Information">
					<ImageUploadInput 	onFileChanged={ handleFileChanged }
										base64={ formikContext.values.ProfilePictureBase64 } 
										mime={ formikContext.values.ProfilePictureMIMEType } 
										name={ formikContext.values.ProfilePictureFileName } 
										label="System Logo" 
										view={props.view}>
					</ImageUploadInput>
				</Panel>
				{
					!props.profile &&
					<Panel title="Active">
						<CheckboxField col={10} view={props.view} name="Active" label="Active"></CheckboxField>
					</Panel>
				}
				
			</React.Fragment>
}