
import ReactPDF from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useSessionStore } from "../../Stores/SessionStore";

import MaturityDate from "./MaturityDate";
import VendorPayments  from "./VendorPayments";
import PayablesDue from "./PayablesDue";
import Recoveries from "./Recoveries";
import { Button} from "react-bootstrap";
import FacilityMovementReport from "./FacilityMovementReport";

export default function ReportPreview(props) {

	const store = useSessionStore();

	const [generatingPreview, setGeneratingPreview] = useState(false);
	
	async function handlePreviewClicked() {
		if(generatingPreview === false) {
			setGeneratingPreview(true);
			var result = null;
			if(props.name ==="Maturity Date Report"){
			result = await store.ReportsService.GetMaturityDateReport(props.fromDate,props.toDate,props.groupId,props.marketPositionId,props.storeId);

			if(result && result.Success) {
				const blob = await ReactPDF.pdf(MaturityDate({ data: result.Data })).toBlob()

				saveAs(blob, `Maturity Date Report.pdf`);

				setGeneratingPreview(false);
			}
		}
			else if(props.name ==="Recoveries Report")
			{
				result = await store.ReportsService.GetRecoveriesReport(props.piId);

			if(result && result.Success) {
				const blob = await ReactPDF.pdf(Recoveries({ data: result.Data })).toBlob()

				saveAs(blob, `Recoveries Report -${result.Data.FinanceDetails.PaymentInstructionBalance.Name}.pdf`);

				setGeneratingPreview(false);
			}
			}
			else if(props.name ==="Payables Due Report")
			{
				result = await store.ReportsService.GetPayablesDueReport(props.piId);

			if(result && result.Success) {
				const blob = await ReactPDF.pdf(PayablesDue({ data: result.Data })).toBlob()

				saveAs(blob, `Payables Due Report - ${result.Data.FinanceDetails.PaymentInstructionBalance.Name}.pdf`);

				setGeneratingPreview(false);
			}
			}
			else if(props.name ==="Contact Payments Report")
			{
				result = await store.ReportsService.GetVendorReport(props.vendorId.Id,props.piId);

			if(result && result.Success) {
				const blob = await ReactPDF.pdf(VendorPayments({ data: result.Data })).toBlob()

				saveAs(blob, `Contact Payment Report - ${result.Data.FinanceDetails.PaymentInstructionBalance.Name}.pdf`);

				setGeneratingPreview(false);
			}
			}
			else if(props.name ==="Facility Movement Report")
			{
				result = await store.ReportsService.GetFacilitiesMovementReport(props.fromDate,props.toDate,props.groupId,props.facilitiesId,props.detailed);

				if(result && result.Success) {
				const blob = await ReactPDF.pdf(FacilityMovementReport({ data: result.Data })).toBlob()

				saveAs(blob, `Facility Movement Report - ${result.Data.Header.GroupName} ${result.Data.Detailed === true ? " (Detailed)" : " (Summary)"}.pdf`);

				setGeneratingPreview(false);
			}
			}
			// else if(props.name ==="Payables Due Report")
			// {

			// }
		}
	}
    return <Button onClick={handlePreviewClicked} disabled={generatingPreview} variant="primary">{generatingPreview? "Running" :"Run"}</Button>
}